import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import pvideo from "../../assets/video/pvideo.mp4"
import pro1 from "../../assets/detail/product1.webp"
import pro2 from "../../assets/detail/product2.webp"
import pro3 from "../../assets/detail/product3.webp"
import pro4 from "../../assets/detail/product4.webp"
import pro5 from "../../assets/detail/product5.webp"
import { FaStar } from 'react-icons/fa';

const VideoPopup = (props) => {
    //console.log(props)
    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                
                <Modal.Body className="videoModalBody">
                    <Container>
                        <Row>
                            <Col md={6}>
                            <Modal.Header className="mobile modalheader" closeButton></Modal.Header>
                                <video preload="none" loop={true} autoPlay={true} playsInline={true} muted={false} mediatype="video">
                                    <source src={pvideo} type="video/mp4" />
                                    <source src={pvideo} type="video/ogg" />
                                </video>
                            </Col>
                            <Col md={6}>
                                <div className="popupproductDetails">
                                    <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} showIndicators={true} swipeable={true} showStatus={false}>
                                        <div className="cimage">
                                            <img src={pro1} />
                                        </div>
                                        <div className="cimage">
                                            <img src={pro2} />
                                        </div>
                                        <div className="cimage">
                                            <img src={pro3} />
                                        </div>
                                        <div className="cimage">
                                            <img src={pro4} />
                                        </div>
                                        <div className="cimage">
                                            <img src={pro5} />
                                        </div>
                                    </Carousel>
                                    <div className="pdetail">
                                        <h1>Ayush Kavach</h1>
                                        <div className="">
                                            <div className="d-flex align-items-baseline">
                                                <small className="text-warning">
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                </small>
                                                <p className="m-0 p-0">
                                                    <span className="totalrev ms-2">998 reviews </span>
                                                </p>
                                            </div>
                                            <p>
                                                <span className="benefits_span text-dark">Trusted by 1 Million+ Happy Customers </span>
                                            </p>
                                            <p className="product__text_description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        </div>
                                        <div className="productPrice">
                                            <div className="pro-price d-flex">
                                                <div className="price">
                                                    <span className="new-price">₹2,900.00</span>
                                                    <span className="cutprice">
                                                        <del>₹3,100.00</del>
                                                    </span>
                                                    <span className="p-discount">Save 6.5%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <Form className="mt-2">
                                            <div className="varientSesction d-flex">
                                                <div className="varient">
                                                    <input type="radio" name="varient" id="varient3" value="3 MONTHS" checked />
                                                    <label className="varientBody" htmlFor="varient3">
                                                        <div className="varientDetail">
                                                            <p className="packType">3 MONTHS</p>
                                                            <p className="packSave">Save Rs. 3,895</p>
                                                            <h4 className="packPrice">Rs. 2,099</h4>
                                                            <del className="cutprice">Rs. 5,994</del>
                                                            <h6 className="totaltablet">540 TABLETS(PACK OF 6)RS.4/TABLET</h6>
                                                            <h6 className="pertablet">RS.4/TABLET</h6>
                                                        </div>
                                                        <p className="packname">Expert Recommend</p>
                                                    </label>
                                                </div>
                                                <div className="varient">
                                                    <input type="radio" name="varient" id="varient2" value="3 MONTHS" />
                                                    <label className="varientBody" htmlFor="varient2">
                                                        <div className="varientDetail">
                                                            <p className="packType">2 MONTHS</p>
                                                            <p className="packSave">Save Rs. 3,895</p>
                                                            <h4 className="packPrice">Rs. 2,099</h4>
                                                            <del className="cutprice">Rs. 5,994</del>
                                                            <h6 className="totaltablet">540 TABLETS(PACK OF 6)RS.4/TABLET</h6>
                                                            <h6 className="pertablet">RS.4/TABLET</h6>
                                                        </div>
                                                        <p className="packname">Bestseller</p>
                                                    </label>
                                                </div>
                                                <div className="varient">
                                                    <input type="radio" name="varient" id="varient1" value="1 MONTHS" />
                                                    <label className="varientBody" htmlFor="varient1">
                                                        <div className="varientDetail">
                                                            <p className="packType">1 MONTHS</p>
                                                            <p className="packSave">Save Rs. 3,895</p>
                                                            <h4 className="packPrice">Rs. 2,099</h4>
                                                            <del className="cutprice">Rs. 5,994</del>
                                                            <h6 className="totaltablet">540 TABLETS(PACK OF 6)RS.4/TABLET</h6>
                                                            <h6 className="pertablet">RS.4/TABLET</h6>
                                                        </div>
                                                        <p className="packname">Start Seeing Results</p>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default VideoPopup