import React from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'

const TermsConditions = () => {
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Terms & Conditions</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumbs">
                            <li class="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Terms & Conditions</li>
                        </ol>
                    </nav>
                    <Row className="aboutbody">
                        <h1 class="text-center">Terms & Conditions</h1>
                        <div class="col-md-12 col-sm-12">
                            <div>
                                <p>Welcome to our website! Before using our services, please read these terms and conditions carefully. By accessing and using our website, you agree to be bound by these terms and conditions.</p>

                                <h3>MEMBERSHIP ELIGIBILITY</h3>

                                <p>You must be at least 18 years of age to use our services. If you are under 18, you must use our website under the guidance and consent of a parent or legal guardian. We sell products for children, but only to adults who can purchase with a credit card or other acceptable payment method.</p>

                                <h3>YOUR ACCOUNT</h3>

                                <p>You are responsible for maintaining the confidentiality of your account password and access to your computer. If you suspect that someone else has access to your account or password, please contact us immediately. We will confirm the situation with you and, if necessary, delete your account. Please provide accurate, current, and complete information when creating your account, and update it promptly if there are any changes.</p>

                                <h3>CANCELLATION BY THE CUSTOMER</h3>

                                <p>You can cancel your order before shipment. Once your order has shipped, it will be governed by our return policy.</p>

                                <h3>DELIVERY</h3>

                                <p>We aim to deliver your order without any issues. Our courier will call you 3-4 hours before delivery to confirm the timing. If you are not available to receive the delivery, our courier will leave a note in your mailbox with instructions for a second attempt within the next 48 hours.</p>

                                <h3>CREDIT CARD DETAILS</h3>

                                <p>We advise you to use your own credit card for transactions on our website. If there is any fraudulent activity related to your credit card, you will be responsible for proving that it was not your fault. We reserve the right to cancel any order if there is suspicion of fraudulent activity.</p>

                                <h3>WARRANTY OF PRODUCTS</h3>

                                <p>We offer a manufacturer's warranty on our products.</p>

                                <h3>MODIFICATION OF TERMS AND CONDITIONS</h3>

                                <p>We may modify these terms and conditions at any time without prior notice. If there are any changes, we will notify you via email. By continuing to use our services, you agree to be bound by the updated terms and conditions.</p>

                                <h3>APPLICABLE CANCELLATION RULES</h3>

                                <p>All purchases are subject to applicable cancellation rules.</p>

                                <h3>RESERVATION OF RIGHTS</h3>

                                <p>We reserve the right to modify or replace this offer, or any part of it, at any time without prior notice.</p>

                                <h3>DISCLAIMER OF LIABILITIES</h3>

                                <p>We do not guarantee that our website will run concurrently and error-free during the promotion period. We are not liable for any technical or human error that may occur.&nbsp;If you have any questions about these terms and conditions, please contact us through our customer support channels.</p>

                                <h3>USE OF WEBSITE CONTENT</h3>

                                <p>All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of our company and is protected by international copyright laws. You may not reproduce, distribute, modify, or create derivative works of any content without our express written consent.</p>

                                <h3>DISCLAIMER OF WARRANTIES</h3>

                                <p>We make no warranties or representations about the accuracy or completeness of the content on this website or the content of any websites linked to this website. We do not guarantee that our website will be error-free, uninterrupted, or free of viruses or other harmful components. You agree to use this website at your own risk.</p>

                                <h3>LIMITATION OF LIABILITY</h3>

                                <p>We will not be liable for any damages arising from your use of this website, including but not limited to direct, indirect, incidental, punitive, and consequential damages. In no event shall our total liability to you for all damages, losses, and causes of action, whether in contract, tort, or otherwise, exceed the amount paid by you, if any, for accessing this website.</p>

                                <h3>LINKS TO OTHER WEBSITES</h3>

                                <p>This website may contain links to other websites that are not under our control. We are not responsible for the content, accuracy, or opinions expressed on those websites, and we do not investigate, monitor, or endorse them. You access those websites at your own risk.</p>

                                <h3>GOVERNING LAW AND JURISDICTION</h3>

                                <p>These terms and conditions are governed by and construed in accordance with the laws of New Delhi/India, without giving effect to any principles of conflicts of law. You agree to submit to the exclusive jurisdiction of the courts located in New Delhi, India for any dispute arising from or related to these terms and conditions or your use of this website.</p>

                                <h3>ENTIRE AGREEMENT</h3>

                                <p>These terms and conditions, together with our privacy policy and any other agreements or policies we may post on this website, constitute the entire agreement between you and us with respect to your use of this website. If any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>

                                <h3>USER CONDUCT</h3>

                                <p>You agree to use this website in a lawful and responsible manner. You agree not to upload, post, email, or otherwise transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable. You also agree not to use this website to impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.</p>

                                <h3>INTELLECTUAL PROPERTY</h3>

                                <p>All trademarks, service marks, logos, and other proprietary content displayed on this website are the property of our company or their respective owners. You may not use any of these marks or content without our express written consent or the consent of the respective owner.</p>

                                <h3>PRODUCT DESCRIPTIONS AND PRICING</h3>

                                <p>We make every effort to ensure that the products and services listed on our website are described accurately and priced correctly. However, we do not guarantee that the descriptions or prices of our products and services are error-free, complete, or current. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information at any time without prior notice.</p>

                                <h3>USER REVIEWS AND COMMENTS</h3>

                                <p>We welcome and encourage user reviews and comments on our website. However, we reserve the right to moderate, edit, or remove any content that we determine to be inappropriate, offensive, or in violation of these terms and conditions. By submitting a review or comment, you agree to grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>

                                <h3>PRIVACY POLICY</h3>

                                <p>Our privacy policy describes how we collect, use, and protect personal information you provide to us through this website. Please review our privacy policy carefully before using this website.</p>

                                <h3>SECURITY</h3>

                                <p>We take reasonable measures to ensure the security of your personal information and to protect against unauthorized access, use, or disclosure. However, we cannot guarantee that our security measures will prevent unauthorized access or use of your information.</p>

                                <h3>TERMINATION</h3>

                                <p>We may terminate your access to this website at any time, without notice, for any reason, including but not limited to a breach of these terms and conditions.</p>

                                <h3>ASSIGNMENT</h3>

                                <p>We may assign these terms and conditions, in whole or in part, at any time without notice to you. You may not assign these terms and conditions, or transfer any rights granted to you under these terms and conditions, without our prior written consent.</p>

                                <h3>CONTACT US</h3>

                                <p>If you have any questions or concerns regarding these terms and conditions, please contact us using the contact information provided on this website.</p>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default TermsConditions