import React from 'react'
import { Container, Image } from 'react-bootstrap'
import MCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import amazon from "../../assets/marketplace/amazon.png"
import flipkart from "../../assets/marketplace/flipkart.png"
import meesho from "../../assets/marketplace/meesho.png"
import shopclues from "../../assets/marketplace/shopclues.png"
import tata1mg from "../../assets/marketplace/tata1mg.png"

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3020, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const MarketPlaceMarquee = () => {
    return (
        <div className="firstbackgroundcolor">
            <div className="">
                <Container>
                    <div className="descHeader text-center">
                        <h2>EASY SHOPPING PLATFORM</h2>
                        <div className="desc">
                            <p>We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>
                        </div>
                    </div>
                    <MCarousel autoPlay={true} responsive={responsive} infinite={true} arrows={false} showDots={true}>
                        <div className="mimg">
                            <Image src={amazon}></Image>
                        </div>
                        <div className="mimg">
                            <Image src={flipkart}></Image>
                        </div>
                        <div className="mimg">
                            <Image src={meesho}></Image>
                        </div>
                        <div className="mimg">
                            <Image src={shopclues}></Image>
                        </div>
                        <div className="mimg">
                            <Image src={tata1mg}></Image>
                        </div>
                    </MCarousel>
                </Container>
            </div>
        </div>
    )
}

export default MarketPlaceMarquee