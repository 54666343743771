import React from 'react'
import { Image } from 'react-bootstrap'
import img1 from "../../assets/topmarquee/nano1.webp"
import img2 from "../../assets/topmarquee/nano2.webp"
import img3 from "../../assets/topmarquee/nano3.webp"
import img4 from "../../assets/topmarquee/nano4.webp"
import img5 from "../../assets/topmarquee/nano5.webp"
import img6 from "../../assets/topmarquee/nano6.webp"

const TopMarquee = () => {
    return (
        <div className="topmarquee">
            <div className="row">
                <marquee direction="left" behavior="scroll" loop={true}>
                    <div className="d-flex">
                        <div className="marqueeBody">
                            <Image src={img1}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img2}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img3}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img4}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img5}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img6}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img1}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img2}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img3}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img4}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img5}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                        <div className="marqueeBody">
                            <Image src={img6}></Image>
                            <span className="text"> Advanced Nanotechnology</span>
                        </div>
                    </div>
                </marquee>
            </div>
        </div >
    )
}

export default TopMarquee