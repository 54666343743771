import React from 'react'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import BCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import product1 from "../../assets/product/product1.webp"
import product2 from "../../assets/product/product2.webp"
import product3 from "../../assets/product/product3.webp"
import product4 from "../../assets/product/product4.webp"
import product5 from "../../assets/product/product5.webp"
import product6 from "../../assets/product/product6.webp"
import product7 from "../../assets/product/product7.webp"
import product8 from "../../assets/product/product8.webp"
import product9 from "../../assets/product/product9.webp"
import product10 from "../../assets/product/product10.webp"
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3020, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const AuthenticAyurveda = () => {
    return (
        <div className="secondbackgroundcolor">
            <div className="desktop">
                <Container className="bestSellerSection">
                    <div className="descHeader text-center">
                        <h2>Authentic Ayurveda: Sat Kartar Group</h2>
                        <div className="desc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, reiciendis. Magni corrupti consequatur ratione? Quibusdam voluptas nesciunt maiores, minima asperiores reprehenderit expedita consequatur perspiciatis sapiente sequi illum neque provident velit. Explore our collection of top selling Ayurvedic products and alternative medicines in India.</p>
                        </div>
                    </div>
                    <BCarousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product1} />
                                <Card.Body>
                                    <Card.Title>Ayush for Men</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>

                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product2} />
                                <Card.Body>
                                    <Card.Title>Ayush for Women</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product3} />
                                <Card.Body>
                                    <Card.Title>Dr. Madhu Amrit</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product4} />
                                <Card.Body>
                                    <Card.Title>Addiction Killer</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product5} />
                                <Card.Body>
                                    <Card.Title>Power Rootz Keeda Jadi</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product6} />
                                <Card.Body>
                                    <Card.Title>Ayur Dia 45</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product7} />
                                <Card.Body>
                                    <Card.Title>Liv Muztang</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product8} />
                                <Card.Body>
                                    <Card.Title>Ayur Sleep</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product9} />
                                <Card.Body>
                                    <Card.Title>Liver Care</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product10} />
                                <Card.Body>
                                    <Card.Title>Dhurandar Joint Pain Oil</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </BCarousel>
                </Container>
            </div>
            <div className="mobile">
                <Container className="bestSellerSection">
                    <div className="descHeader text-center">
                        <h2>Authentic Ayurveda: Sat Kartar Group</h2>
                        <div className="desc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, reiciendis. Magni corrupti consequatur ratione? Quibusdam voluptas nesciunt maiores, minima asperiores reprehenderit expedita consequatur perspiciatis sapiente sequi illum neque provident velit. Explore our collection of top selling Ayurvedic products and alternative medicines in India.</p>
                        </div>
                    </div>
                    <div className="mobilebestseller">
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product1} />
                                <Card.Body>
                                    <Card.Title>Ayush for Men</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>

                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product2} />
                                <Card.Body>
                                    <Card.Title>Ayush for Women</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product3} />
                                <Card.Body>
                                    <Card.Title>Dr. Madhu Amrit</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product4} />
                                <Card.Body>
                                    <Card.Title>Addiction Killer</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product5} />
                                <Card.Body>
                                    <Card.Title>Power Rootz Keeda Jadi</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product6} />
                                <Card.Body>
                                    <Card.Title>Ayur Dia 45</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product7} />
                                <Card.Body>
                                    <Card.Title>Liv Muztang</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product8} />
                                <Card.Body>
                                    <Card.Title>Ayur Sleep</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product9} />
                                <Card.Body>
                                    <Card.Title>Liver Care</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="bestseller-body">
                            <Card>
                                <Card.Img variant="top" src={product10} />
                                <Card.Body>
                                    <Card.Title>Dhurandar Joint Pain Oil</Card.Title>
                                    <Card.Text>
                                        <div className="offersection">
                                            <div className="priceSection">
                                                <span className="price">₹699.00</span>
                                                <del className="cutprice">₹100.00</del>
                                            </div>
                                            <div className="offer">
                                                <span>10% off</span>
                                            </div>
                                        </div>
                                        <div className="ratingSection">
                                            <span className="rating">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />
                                            </span>
                                            <span className="totalreview">374 reviews</span>
                                        </div>
                                    </Card.Text>
                                    <div className="buttonSection">
                                        <Button className="addtocart">Add To Cart</Button>
                                        <Button className="buyitnow">Buy It Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default AuthenticAyurveda