import React from 'react'
import { Card, Container } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import RCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3020, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const Review = () => {
    return (
        <div className="secondbackgroundcolor">
            <div className="desktop">
                <Container>
                    <div className="descHeader text-center">
                        <h2>WHAT OUR CUSTOMER SAY ABOUT US</h2>
                        <div className="desc">
                            <p>We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>
                        </div>
                    </div>
                    <RCarousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </RCarousel>
                </Container>
            </div>
            <div className="mobile mobileconcern">
                <Container>
                    <div className="descHeader text-center">
                        <h2>WHAT OUR CUSTOMER SAY ABOUT US</h2>
                        <div className="desc">
                            <p>We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>
                        </div>
                    </div>
                    <div className="mobileReview">
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="homereating">
                            <Card.Body>
                                <div className="namerating">
                                    <span className="rating">
                                        4.5 <FaStar />
                                    </span>
                                    <div className="user-name">
                                        <h6 content="Kiara Keshvani">Sharath</h6>
                                    </div>
                                </div>
                                <Card.Text>
                                    "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                </Card.Text>
                                <div className="postdate">
                                    <span className="duration">
                                        a year ago
                                    </span>
                                    <div className="location">
                                        <h6 className="user-location"> Ambala, Punjab</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            </div>

        </div>
    )
}

export default Review