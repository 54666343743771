import React from 'react'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
import Header from '../components/Header.'
import { Col, Container, Row } from 'react-bootstrap'
import { Step, Stepper } from 'react-form-stepper'
import { Link } from 'react-router-dom'
import { FaMinus, FaMobileAlt, FaPlus, FaUserAlt } from 'react-icons/fa'
import { FaLocationDot, FaRegTrashCan } from 'react-icons/fa6'

const OrderSummary = () => {
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Order Summary</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="contentBody mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">order summary</li>
                        </ol>
                    </nav>
                    <div className="steper">
                        <Row>
                            <div className="spetbar-card">
                                <Stepper activeStep={2}>
                                    <Step label="LOGIN" />
                                    <Step label="DELIVERY ADDRESS" />
                                    <Step label="ORDER SUMMARY" />
                                    <Step label="PAYMENT OPTIONS" />
                                </Stepper>
                            </div>
                        </Row>
                    </div>
                    <div className="contentBody">
                        <div className="account-card">
                            <div className="account-title">
                                <h4>Billing Address</h4>
                                <Link className="changeaddress" to="/delivery-address">Change Address</Link>
                            </div>
                            <div className="account-content">
                                <div className="billingsAddress">
                                    <FaUserAlt className="userIcons" />
                                    <p>Sushil Kumar Raj</p>
                                </div>
                                <div className="billingsAddress">
                                    <FaLocationDot className="locationIcon" />
                                    <p>6th Floor, Unit Number 603, Mercantile House, K.G. Marg, Delhi, 110001</p>
                                </div>
                                <div className="billingsAddress">
                                    <FaMobileAlt className="locationIcon" />
                                    <b>+91-9876543210</b>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <Row>
                                <Col md={8}>
                                    <div className="card-summary">
                                        <div className="col-md-12">
                                            <div className="card-summary-details">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="cartImg">
                                                            <img src="https://backendsatkartar.satkartar.co.in/assets/img/product/addiction_killer_powder.webp" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="orderSummaryDetailsPrice">
                                                            <h4>Addiction Killer, No Addiction Powder, Drink Stop Medicine, Leave Smack, Ganja, Smoking and Tobacco Addiction
                                                                <button className="btntrash">
                                                                    <FaRegTrashCan />
                                                                </button></h4>
                                                            <p>QTY: <b>1</b></p>
                                                            <p>price: ₹2,999.00</p>
                                                            <p>Sub Total: <b>₹2,999.00</b></p>
                                                            <div className="cart-action-group mt-4">
                                                                <div className="product-action">
                                                                    <button className="action-minus" title="Quantity Minus">
                                                                        <FaMinus />
                                                                    </button>
                                                                    <input className="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value="1" readOnly={true} />
                                                                    <button className="action-plus" title="Quantity Plus">
                                                                        <FaPlus />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-summary">
                                        <div className="col-md-12">
                                            <div className="card-summary-details">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="cartImg">
                                                            <img src="https://backendsatkartar.satkartar.co.in/assets/img/product/addiction_killer_powder.webp" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="orderSummaryDetailsPrice">
                                                            <h4>Addiction Killer, No Addiction Powder, Drink Stop Medicine, Leave Smack, Ganja, Smoking and Tobacco Addiction
                                                                <button className="btntrash">
                                                                    <FaRegTrashCan />
                                                                </button></h4>
                                                            <p>QTY: <b>1</b></p>
                                                            <p>price: ₹2,999.00</p>
                                                            <p>Sub Total: <b>₹2,999.00</b></p>
                                                            <div className="cart-action-group mt-4">
                                                                <div className="product-action">
                                                                    <button className="action-minus" title="Quantity Minus">
                                                                        <FaMinus />
                                                                    </button>
                                                                    <input className="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value="1" readOnly={true} />
                                                                    <button className="action-plus" title="Quantity Plus">
                                                                        <FaPlus />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="account-card">
                                        <div className="account-title">
                                            <h4>Price Details</h4>
                                        </div>
                                        <div className="price-summary">
                                            <div className="price-summary-details">
                                                <span>Sub Total</span>
                                                <span>₹2,999.00</span>
                                            </div>
                                            <div className="price-summary-details">
                                                <span>Discount</span>
                                                <span className="priceCharge"><b>0.00</b></span>
                                            </div>
                                            <div className="price-summary-details">
                                                <span>Delivery Charges</span>
                                                <span className="priceCharge"><b>Free</b></span>
                                            </div>
                                            <div className="price-summary-details total-amount-details">
                                                <span><b>Total Amount</b></span>
                                                <span><b>₹2,999.00</b></span>
                                            </div>
                                            <div className="price-summary-payment">
                                                <button >PAYMENT OPTIONS</button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default OrderSummary