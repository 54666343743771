import React from 'react'
import Header from '../components/Header.'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import product1 from "../assets/product/product1.webp"
import product2 from "../assets/product/product2.webp"
import product3 from "../assets/product/product3.webp"
import product4 from "../assets/product/product4.webp"
import product5 from "../assets/product/product5.webp"
import product6 from "../assets/product/product6.webp"
import product7 from "../assets/product/product7.webp"
import product8 from "../assets/product/product8.webp"
import product9 from "../assets/product/product9.webp"
import product10 from "../assets/product/product10.webp"
import { FaStar, FaStarHalfAlt } from 'react-icons/fa'


const Product = () => {
    return (
        <div className="products themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Product</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="productseaction mt-2 mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">products</li>
                        </ol>
                    </nav>
                    <Row>
                        <Col md={3} sm={12}>
                            <div class="shop-widget">
                                <h6 class="shop-widget-title">Filter by Probelm</h6>
                                <form method="post" class="filterForm">
                                    <ul class="shop-widget-list shop-widget-scroll">
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-3" name="pcategory" value="3" />
                                                <label for="category-3">Women's Health</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-4" name="pcategory" value="4" />
                                                <label for="category-4">Men's Health</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-6" name="pcategory" value="6" />
                                                <label for="category-6">Personal Care</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-7" name="pcategory" value="7" />
                                                <label for="category-7">Hair Care</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-8" name="pcategory" value="8" />
                                                <label for="category-8">Diabetes Care</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-9" name="pcategory" value="9" />
                                                <label for="category-9">Piles Care</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-10" name="pcategory" value="10" />
                                                <label for="category-10">Addiction</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-11" name="pcategory" value="11" />
                                                <label for="category-11">Immune Support</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-12" name="pcategory" value="12" />
                                                <label for="category-12">Bone and Joint</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-13" name="pcategory" value="13" />
                                                <label for="category-13">Lungs Care</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-14" name="pcategory" value="14" />
                                                <label for="category-14">PCOS PCOD</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-15" name="pcategory" value="15" />
                                                <label for="category-15">Respiratory Health</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-16" name="pcategory" value="16" />
                                                <label for="category-16">Sexual Wellness</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-17" name="pcategory" value="17" />
                                                <label for="category-17">Liver Care</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-18" name="pcategory" value="18" />
                                                <label for="category-18">Infertility Support</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="category-19" name="pcategory" value="19" />
                                                <label for="category-19">Mind Care</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shop-widget-content">
                                                <input type="radio" id="all" name="pcategory" value="all" />
                                                <label for="all">All</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </Col>
                        <Col md={9} sm={12}>
                            <div class="productTopBar">
                                <div class="productViewTitle">
                                    <h4>Ayurveda Products</h4>
                                </div>
                            </div>
                            <Row>
                                <Col md={4} sm={6}>
                                    <Card className="product-body">
                                        <Card.Img variant="top" src={product1} />
                                        <Card.Body>
                                            <Card.Title>Ayush for Men</Card.Title>
                                            <Card.Text>
                                                <div className="offersection">
                                                    <div className="priceSection">
                                                        <span className="price">₹699.00</span>
                                                        <del className="cutprice">₹100.00</del>
                                                    </div>
                                                    <div className="offer">
                                                        <span>10% off</span>
                                                    </div>
                                                </div>

                                                <div className="ratingSection">
                                                    <span className="rating">
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStarHalfAlt />
                                                    </span>
                                                    <span className="totalreview">374 reviews</span>
                                                </div>
                                            </Card.Text>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} sm={6}>
                                    <Card className="product-body">
                                        <Card.Img variant="top" src={product2} />
                                        <Card.Body>
                                            <Card.Title>Ayush for Women</Card.Title>
                                            <Card.Text>
                                                <div className="offersection">
                                                    <div className="priceSection">
                                                        <span className="price">₹699.00</span>
                                                        <del className="cutprice">₹100.00</del>
                                                    </div>
                                                    <div className="offer">
                                                        <span>10% off</span>
                                                    </div>
                                                </div>
                                                <div className="ratingSection">
                                                    <span className="rating">
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStarHalfAlt />
                                                    </span>
                                                    <span className="totalreview">374 reviews</span>
                                                </div>
                                            </Card.Text>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} sm={6}>
                                    <Card className="product-body">
                                        <Card.Img variant="top" src={product3} />
                                        <Card.Body>
                                            <Card.Title>Dr. Madhu Amrit</Card.Title>
                                            <Card.Text>
                                                <div className="offersection">
                                                    <div className="priceSection">
                                                        <span className="price">₹699.00</span>
                                                        <del className="cutprice">₹100.00</del>
                                                    </div>
                                                    <div className="offer">
                                                        <span>10% off</span>
                                                    </div>
                                                </div>
                                                <div className="ratingSection">
                                                    <span className="rating">
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStarHalfAlt />
                                                    </span>
                                                    <span className="totalreview">374 reviews</span>
                                                </div>
                                            </Card.Text>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} sm={6}>
                                    <Card className="product-body">
                                        <Card.Img variant="top" src={product4} />
                                        <Card.Body>
                                            <Card.Title>Addiction Killer</Card.Title>
                                            <Card.Text>
                                                <div className="offersection">
                                                    <div className="priceSection">
                                                        <span className="price">₹699.00</span>
                                                        <del className="cutprice">₹100.00</del>
                                                    </div>
                                                    <div className="offer">
                                                        <span>10% off</span>
                                                    </div>
                                                </div>
                                                <div className="ratingSection">
                                                    <span className="rating">
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStarHalfAlt />
                                                    </span>
                                                    <span className="totalreview">374 reviews</span>
                                                </div>
                                            </Card.Text>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} sm={6}>
                                    <Card className="product-body">
                                        <Card.Img variant="top" src={product5} />
                                        <Card.Body>
                                            <Card.Title>Power Rootz Keeda Jadi</Card.Title>
                                            <Card.Text>
                                                <div className="offersection">
                                                    <div className="priceSection">
                                                        <span className="price">₹699.00</span>
                                                        <del className="cutprice">₹100.00</del>
                                                    </div>
                                                    <div className="offer">
                                                        <span>10% off</span>
                                                    </div>
                                                </div>
                                                <div className="ratingSection">
                                                    <span className="rating">
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStarHalfAlt />
                                                    </span>
                                                    <span className="totalreview">374 reviews</span>
                                                </div>
                                            </Card.Text>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} sm={6}>
                                    <Card className="product-body">
                                        <Card.Img variant="top" src={product6} />
                                        <Card.Body>
                                            <Card.Title>Ayur Dia 45</Card.Title>
                                            <Card.Text>
                                                <div className="offersection">
                                                    <div className="priceSection">
                                                        <span className="price">₹699.00</span>
                                                        <del className="cutprice">₹100.00</del>
                                                    </div>
                                                    <div className="offer">
                                                        <span>10% off</span>
                                                    </div>
                                                </div>
                                                <div className="ratingSection">
                                                    <span className="rating">
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStarHalfAlt />
                                                    </span>
                                                    <span className="totalreview">374 reviews</span>
                                                </div>
                                            </Card.Text>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} sm={6}>
                                    <Card className="product-body">
                                        <Card.Img variant="top" src={product7} />
                                        <Card.Body>
                                            <Card.Title>Liv Muztang</Card.Title>
                                            <Card.Text>
                                                <div className="offersection">
                                                    <div className="priceSection">
                                                        <span className="price">₹699.00</span>
                                                        <del className="cutprice">₹100.00</del>
                                                    </div>
                                                    <div className="offer">
                                                        <span>10% off</span>
                                                    </div>
                                                </div>
                                                <div className="ratingSection">
                                                    <span className="rating">
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStarHalfAlt />
                                                    </span>
                                                    <span className="totalreview">374 reviews</span>
                                                </div>
                                            </Card.Text>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} sm={6}>
                                    <Card className="product-body">
                                        <Card.Img variant="top" src={product8} />
                                        <Card.Body>
                                            <Card.Title>Ayur Sleep</Card.Title>
                                            <Card.Text>
                                                <div className="offersection">
                                                    <div className="priceSection">
                                                        <span className="price">₹699.00</span>
                                                        <del className="cutprice">₹100.00</del>
                                                    </div>
                                                    <div className="offer">
                                                        <span>10% off</span>
                                                    </div>
                                                </div>
                                                <div className="ratingSection">
                                                    <span className="rating">
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStarHalfAlt />
                                                    </span>
                                                    <span className="totalreview">374 reviews</span>
                                                </div>
                                            </Card.Text>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} sm={6}>
                                    <Card className="product-body">
                                        <Card.Img variant="top" src={product9} />
                                        <Card.Body>
                                            <Card.Title>Dhurandar Joint Pain Oil</Card.Title>
                                            <Card.Text>
                                                <div className="offersection">
                                                    <div className="priceSection">
                                                        <span className="price">₹699.00</span>
                                                        <del className="cutprice">₹100.00</del>
                                                    </div>
                                                    <div className="offer">
                                                        <span>10% off</span>
                                                    </div>
                                                </div>
                                                <div className="ratingSection">
                                                    <span className="rating">
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStarHalfAlt />
                                                    </span>
                                                    <span className="totalreview">374 reviews</span>
                                                </div>
                                            </Card.Text>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} sm={6}>
                                    <Card className="product-body">
                                        <Card.Img variant="top" src={product10} />
                                        <Card.Body>
                                            <Card.Title>Dhurandar Joint Pain Oil</Card.Title>
                                            <Card.Text>
                                                <div className="offersection">
                                                    <div className="priceSection">
                                                        <span className="price">₹699.00</span>
                                                        <del className="cutprice">₹100.00</del>
                                                    </div>
                                                    <div className="offer">
                                                        <span>10% off</span>
                                                    </div>
                                                </div>
                                                <div className="ratingSection">
                                                    <span className="rating">
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStar />
                                                        <FaStarHalfAlt />
                                                    </span>
                                                    <span className="totalreview">374 reviews</span>
                                                </div>
                                            </Card.Text>
                                            <div className="buttonSection">
                                                <Button className="addtocart">Add To Cart</Button>
                                                <Button className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Product