import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import slider1 from "../../assets/banner/slider1.webp"
import slider2 from "../../assets/banner/sliver2.webp"
import slider3 from "../../assets/banner/slider3.webp"
import mobile1 from "../../assets/banner/mob-slider1.webp"
import mobile2 from "../../assets/banner/mob-slider2.webp"
import mobile3 from "../../assets/banner/mob-slider3.webp"
import mobile4 from "../../assets/banner/mob-slider4.webp"

const Slider = () => {
    return (
        <>
            <div className="desktop">
                <Carousel data-bs-theme="dark">
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={slider1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={slider2}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={slider3}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="mobile">
                <Carousel data-bs-theme="dark">
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={mobile1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={mobile2}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={mobile3}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={mobile4}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
        </>
    );
}

export default Slider