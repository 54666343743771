import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header.'
import Footer from '../components/Footer'
import shop1 from "../assets/shop/shop1.png"
import shop2 from "../assets/shop/shop2.png"
import shop3 from "../assets/shop/shop3.png"
import shop4 from "../assets/shop/shop4.png"
import shop5 from "../assets/shop/shop5.png"
import shop6 from "../assets/shop/shop6.png"
import shop7 from "../assets/shop/shop7.png"
import shop8 from "../assets/shop/shop8.png"
import { Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ShopByConcern = () => {
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Shop By Concern</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumbs">
                            <li class="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">ingredients</li>
                        </ol>
                    </nav>
                    <Row className="shopByConcernbody">
                        <Link to="/" className="shopByConcern">
                            <Image src={shop1} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop2} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop3} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop4} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop5} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop6} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop7} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop8} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop1} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop2} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop3} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop4} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop5} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop6} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop7} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop8} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop1} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop2} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop3} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop4} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop5} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop6} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop7} rounded />
                        </Link>
                        <Link to="/" className="shopByConcern">
                            <Image src={shop8} rounded />
                        </Link>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default ShopByConcern