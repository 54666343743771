import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../assets/logo.webp";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { IoSearchOutline } from "react-icons/io5";
import { FaMinus, FaPlus, FaShoppingBag, FaShoppingCart } from "react-icons/fa";
import { BiCurrentLocation } from "react-icons/bi";
import { ImLocation2 } from "react-icons/im";
import { IoMdMenu } from "react-icons/io";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaMicrophone } from "react-icons/fa";
import { FaRegTrashCan } from 'react-icons/fa6';
import LoginPopup from './LoginPopup';


const MegaMenuBar = () => {
    const navigate = useNavigate();
    const clickMobileMegaMenu = (url) => {
        navigate(url);
    }
    const [loginPopupShow, setLoginPopupShow] = useState(false);
    const [cartshow, setCartShow] = useState(false);
    const handleCartClose = () => setCartShow(false);
    const handleCartShow = () => setCartShow(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [megashow, setMegaShow] = useState("");
    const [megamobileshow, setMegaMobileShow] = useState("");
    const [lastScrolly, setLastScrolly] = useState(0);

    const controlNavbar = () => {
        if (window.scrollY > 150) {
            if (window.screenY > lastScrolly) {
                setMegaShow("hide");
            }
            else {
                setMegaShow("show");
            }
        }
        else {
            setMegaShow();
        }
        setLastScrolly(window.scrollY);
    }
    const controlMobileNavbar = () => {
        if (window.scrollY > 90) {
            if (window.screenY > lastScrolly) {
                setMegaMobileShow("hide");
            }
            else {
                setMegaMobileShow("show");
            }
        }
        else {
            setMegaMobileShow();
        }
        setLastScrolly(window.scrollY);
    }
    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        window.addEventListener("scroll", controlMobileNavbar);
        return () => {
            window.addEventListener("scroll", controlNavbar);
            window.addEventListener("scroll", controlMobileNavbar);
        }
    }, [lastScrolly]);
    return (
        <>
            <LoginPopup
                show={loginPopupShow}
                onHide={() => setLoginPopupShow(false)}
            />
            <div className={`desktop ${megashow}`}>
                <Navbar className="headerSearchBar themebackgroundcolor">
                    <Container>
                        <div className="headerLogo">
                            <Link to="/">
                                <img src={logo} alt={logo} />
                            </Link>
                        </div>
                        <div className="headerSearch">
                            <Nav className="me-auto">
                                <form method="GET" name="site-search" role="search">
                                    <div className="locationBox">
                                        <ImLocation2 />
                                        <input type="text" name="search_name" placeholder="Enter Your City" />
                                        <span className="btnlocation">
                                            <BiCurrentLocation />
                                        </span>
                                    </div>
                                    <div className="searchBox">
                                        <input type="text" name="search_name" placeholder="Search for Medicines and Health Products" />
                                        <div className="searchtbn">
                                            <button type="submit" className="searchiconssubmit">
                                                <IoSearchOutline className="header_search_icon" />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </Nav>
                        </div>
                        <div className="headerCart">
                            <div className="topCartCounter">
                                <FaShoppingBag className="cartIcons" onClick={handleCartShow} />
                                <span className="cartCounter">0</span>
                            </div>
                            <div className="yourCart">
                                <span className="textCart">TOTAL PRICE</span>< br />
                                <span className="totalPriceCart">₹0.00</span>
                            </div>
                        </div>
                    </Container>
                </Navbar>
                <Navbar bg="white" data-bs-theme="dark" className="navbarsection">
                    <Container>
                        <Nav className="me-auto desktopnavbar">
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/')} to="/">HOME</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">AYURVEDA PRODUCTS</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">VITAMIN & SUPPLEMENTS</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">HERBAL</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">INGREDIENTS</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">SHOP BY CONCERN</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">ABOUT US</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">BLOG</NavLink>
                        </Nav>
                    </Container>
                </Navbar>
            </div>
            <div className={`mobile ${megamobileshow}`}>
                <Navbar bg="white" data-bs-theme="light" className="mobileHeader">
                    <Container>
                        <div className="mobileMenuIcons">
                            <IoMdMenu onClick={handleShow} />
                        </div>
                        <div className="mobileheaderLogo">
                            <Link to="/">
                                <img src={logo} alt={logo} />
                            </Link>
                        </div>
                        <div className="mobileheaderCart">
                            <div className="topCartCounter">
                                <FaShoppingBag className="cartIcons" onClick={handleCartShow} />
                                <span className="cartCounter">0</span>
                            </div>
                        </div>
                    </Container>
                    <Container>
                        <div className="mobileHeaderSearch">
                            <form method="GET" name="site-search" role="search">
                                <div className="searchBox">
                                    <input type="text" name="search_name" placeholder="Search for Medicines and Health Products" />
                                    <div className="searchtbn">
                                        <FaMicrophone />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Container>
                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <div className="mobileheaderLogo">
                                    <Link to="/">
                                        <img src={logo} alt={logo} />
                                    </Link>
                                </div>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="me-auto mobilenavbar">
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/')} to="/">HOME</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">AYURVEDA PRODUCTS</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">VITAMIN & SUPPLEMENTS</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">HERBAL</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">INGREDIENTS</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">SHOP BY CONCERN</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">ABOUT US</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">BLOG</NavLink>
                                <span className="mobilelogin" onClick={() => setLoginPopupShow(true)} >Login</span>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Offcanvas show={cartshow} onHide={handleCartClose} placement="end" name="end" backdrop="static" className="popupCart">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <FaShoppingCart />
                                <span className="totalitem">total item (<span>0</span>)</span>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="cartItems">
                                <ul>
                                    <li className="cartList">
                                        <div className="cartBody">
                                            <div class="cart-media">
                                                <img src="https://backendsatkartar.satkartar.co.in/assets/img/product/addiction_killer_powder.webp" alt="addiction_killer_powder.webp" title="addiction_killer_powder.webp" />
                                            </div>
                                            <div class="cart-info-group">
                                                <div class="cart-info">
                                                    <h6>Addiction Killer, No Addiction Powder, Drink Stop Medicine, Leave Smack, Ganja, Smoking and Tobacco Addiction</h6>
                                                    <p>Price - ₹2,999.00</p>
                                                </div>
                                                <div class="cart-action-group">
                                                    <div class="product-action">
                                                        <button class="action-minus deskminus">
                                                            <FaMinus />
                                                        </button>
                                                        <input class="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value="1" />
                                                        <button class="action-plus deskplus">
                                                            <FaPlus />
                                                        </button>
                                                    </div>
                                                    <h6>₹2,999.00</h6>
                                                </div>
                                            </div>
                                            <div class="cartTrash">
                                                <button class="cart-delete">
                                                    <FaRegTrashCan className="cartItemTrash" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="cartList">
                                        <div className="cartBody">
                                            <div class="cart-media">
                                                <img src="https://backendsatkartar.satkartar.co.in/assets/img/product/addiction_killer_powder.webp" alt="addiction_killer_powder.webp" title="addiction_killer_powder.webp" />
                                            </div>
                                            <div class="cart-info-group">
                                                <div class="cart-info">
                                                    <h6>Addiction Killer, No Addiction Powder, Drink Stop Medicine, Leave Smack, Ganja, Smoking and Tobacco Addiction</h6>
                                                    <p>Price - ₹2,999.00</p>
                                                </div>
                                                <div class="cart-action-group">
                                                    <div class="product-action">
                                                        <button class="action-minus deskminus">
                                                            <FaMinus />
                                                        </button>
                                                        <input class="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value="1" />
                                                        <button class="action-plus deskplus">
                                                            <FaPlus />
                                                        </button>
                                                    </div>
                                                    <h6>₹2,999.00</h6>
                                                </div>
                                            </div>
                                            <div class="cartTrash">
                                                <button class="cart-delete">
                                                    <FaRegTrashCan className="cartItemTrash" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="cartList">
                                        <div className="cartBody">
                                            <div class="cart-media">
                                                <img src="https://backendsatkartar.satkartar.co.in/assets/img/product/addiction_killer_powder.webp" alt="addiction_killer_powder.webp" title="addiction_killer_powder.webp" />
                                            </div>
                                            <div class="cart-info-group">
                                                <div class="cart-info">
                                                    <h6>Addiction Killer, No Addiction Powder, Drink Stop Medicine, Leave Smack, Ganja, Smoking and Tobacco Addiction</h6>
                                                    <p>Price - ₹2,999.00</p>
                                                </div>
                                                <div class="cart-action-group">
                                                    <div class="product-action">
                                                        <button class="action-minus deskminus">
                                                            <FaMinus />
                                                        </button>
                                                        <input class="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value="1" />
                                                        <button class="action-plus deskplus">
                                                            <FaPlus />
                                                        </button>
                                                    </div>
                                                    <h6>₹2,999.00</h6>
                                                </div>
                                            </div>
                                            <div class="cartTrash">
                                                <button class="cart-delete">
                                                    <FaRegTrashCan className="cartItemTrash" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="cartList">
                                        <div className="cartBody">
                                            <div class="cart-media">
                                                <img src="https://backendsatkartar.satkartar.co.in/assets/img/product/addiction_killer_powder.webp" alt="addiction_killer_powder.webp" title="addiction_killer_powder.webp" />
                                            </div>
                                            <div class="cart-info-group">
                                                <div class="cart-info">
                                                    <h6>Addiction Killer, No Addiction Powder, Drink Stop Medicine, Leave Smack, Ganja, Smoking and Tobacco Addiction</h6>
                                                    <p>Price - ₹2,999.00</p>
                                                </div>
                                                <div class="cart-action-group">
                                                    <div class="product-action">
                                                        <button class="action-minus deskminus">
                                                            <FaMinus />
                                                        </button>
                                                        <input class="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value="1" />
                                                        <button class="action-plus deskplus">
                                                            <FaPlus />
                                                        </button>
                                                    </div>
                                                    <h6>₹2,999.00</h6>
                                                </div>
                                            </div>
                                            <div class="cartTrash">
                                                <button class="cart-delete">
                                                    <FaRegTrashCan className="cartItemTrash" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="cartList">
                                        <div className="cartBody">
                                            <div class="cart-media">
                                                <img src="https://backendsatkartar.satkartar.co.in/assets/img/product/addiction_killer_powder.webp" alt="addiction_killer_powder.webp" title="addiction_killer_powder.webp" />
                                            </div>
                                            <div class="cart-info-group">
                                                <div class="cart-info">
                                                    <h6>Addiction Killer, No Addiction Powder, Drink Stop Medicine, Leave Smack, Ganja, Smoking and Tobacco Addiction</h6>
                                                    <p>Price - ₹2,999.00</p>
                                                </div>
                                                <div class="cart-action-group">
                                                    <div class="product-action">
                                                        <button class="action-minus deskminus">
                                                            <FaMinus />
                                                        </button>
                                                        <input class="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value="1" />
                                                        <button class="action-plus deskplus">
                                                            <FaPlus />
                                                        </button>
                                                    </div>
                                                    <h6>₹2,999.00</h6>
                                                </div>
                                            </div>
                                            <div class="cartTrash">
                                                <button class="cart-delete">
                                                    <FaRegTrashCan className="cartItemTrash" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="cartList">
                                        <div className="cartBody">
                                            <div class="cart-media">
                                                <img src="https://backendsatkartar.satkartar.co.in/assets/img/product/addiction_killer_powder.webp" alt="addiction_killer_powder.webp" title="addiction_killer_powder.webp" />
                                            </div>
                                            <div class="cart-info-group">
                                                <div class="cart-info">
                                                    <h6>Addiction Killer, No Addiction Powder, Drink Stop Medicine, Leave Smack, Ganja, Smoking and Tobacco Addiction</h6>
                                                    <p>Price - ₹2,999.00</p>
                                                </div>
                                                <div class="cart-action-group">
                                                    <div class="product-action">
                                                        <button class="action-minus deskminus">
                                                            <FaMinus />
                                                        </button>
                                                        <input class="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value="1" />
                                                        <button class="action-plus deskplus">
                                                            <FaPlus />
                                                        </button>
                                                    </div>
                                                    <h6>₹2,999.00</h6>
                                                </div>
                                            </div>
                                            <div class="cartTrash">
                                                <button class="cart-delete">
                                                    <FaRegTrashCan className="cartItemTrash" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div class="cart-footer">
                                    <a href="javascript:void(0)" class="cart-checkout-btn">
                                        <span class="checkout-label">Proceed to Checkout</span>&nbsp;<span class="checkout-price">₹5,899.00</span>
                                    </a>
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Navbar>
            </div>
        </>
    )
}

export default MegaMenuBar