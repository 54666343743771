import React from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import Accordion from 'react-bootstrap/Accordion';

const Faqs = () => {
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Faqs</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumbs">
                            <li class="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Faqs</li>
                        </ol>
                    </nav>
                    <Row className="faqsbody mt-2">
                        <div className="titledesc">
                            <h1 class="text-center">Faqs</h1>
                            <p>We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>Can Ayurvedic medicine cure diabetes?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Well it has been a topic of much debate as to wheather Ayurvedic medicine can or cannot cure diabetes. However, it has been proven that Ayurvedic medicine can be quite effective and can help manage the condition by regulating blood sugar levels and promoting overall health.</p>

                                    <p>Ayurvedic medicine may not be a sureshot cure for diabetes, but it can be an effective complementary treatment to help manage the condition. Ayurvedic treatments for diabetes focus on lifestyle modifications, diet changes, and herbal remedies. Ayurvedic practitioners believe that diabetes is caused by an imbalance in the body's three doshas, and aim to restore balance through various treatments.</p>

                                    <p>Some Ayurvedic herbs and supplements have been shown to help improve insulin sensitivity and lower blood sugar levels, such as bitter melon, fenugreek, and Gymnema sylvestre. However, it's important to note that Ayurvedic treatments should not be used as a replacement for conventional medical treatment, including prescription medications and insulin therapy.</p>

                                    <p>Before using any Ayurvedic remedies for diabetes, it's important to consult with a qualified Ayurvedic practitioner and also inform your primary care physician about any alternative treatments you plan to use. This is particularly important if you are already taking prescription medications to manage your diabetes, as some Ayurvedic remedies may interact with these medications or interfere with their effectiveness.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Faqs