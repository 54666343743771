import React from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'

const RefundCancellationPolicy = () => {
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Refund & Cancellation Policy</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumbs">
                            <li class="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Refund & Cancellation Policy</li>
                        </ol>
                    </nav>
                    <Row className="aboutbody">
                        <h1 class="text-center">Refund & Cancellation Policy</h1>
                        <div class="col-md-12 col-sm-12">
                            <div>
                                <p>At Sat Kartar Shopping, we want you to be completely satisfied with your purchase. We understand that sometimes you may receive a product that is damaged or not as described. In such cases, we offer a full or partial refund at the discretion of our website management. To request a refund, please contact us within 2 days of receiving your order.</p>

                                <p>Please note that refunds or replacements can only be granted if the product is unused and in its original packaging. If the original packing has been opened or the product has been used, we cannot accept a refund or replacement request.</p>

                                <p>If you do need to return a product, it must be returned to us within 15 days of receiving it. You will be responsible for the cost of shipping the product back to us. Once we have received the returned product, we will inspect it to ensure it meets our refund policy criteria. If it does, we will process the refund or replacement within 7 business days.</p>

                                <p>We take great care to ensure that our products are of high quality and are packaged securely to prevent any damage during shipping. However, if you do receive a damaged product, please contact us immediately so we can initiate a refund or replacement.</p>

                                <p>We value our customers and want to ensure that you have a positive experience shopping with us. If you have any questions about our refund or replacement policy, please do not hesitate to contact us. Our customer service team is always available to assist you.</p>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default RefundCancellationPolicy