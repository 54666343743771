import React from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Accordion, Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import pro1 from "../assets/detail/product1.webp"
import pro2 from "../assets/detail/product2.webp"
import pro3 from "../assets/detail/product3.webp"
import pro4 from "../assets/detail/product4.webp"
import pro5 from "../assets/detail/product5.webp"
import benef1 from "../assets/detail/Benifit1.avif"
import benef2 from "../assets/detail/Benifit2.avif"
import benef3 from "../assets/detail/Benifit3.avif"
import benef4 from "../assets/detail/Benifit4.avif"
import benef5 from "../assets/detail/Benifit5.avif"
import benef6 from "../assets/detail/Benifit6.avif"
import { FaStar } from 'react-icons/fa'
import TopMarquee from '../components/homepage/TopMarquee'
import MarketPlaceMarquee from '../components/homepage/MarketPlaceMarquee'

const ProductDetail = () => {
    return (
        <div className="bg-white">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Ayush Kavach</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="pdetails mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Ayush Kavach</li>
                        </ol>
                    </nav>
                    <Row className="productDetailbody bg-white mt-2">
                        <Col>
                            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={true} showIndicators={true} swipeable={true} showStatus={false}>
                                <div className="cimage">
                                    <img src={pro1} />
                                </div>
                                <div className="cimage">
                                    <img src={pro2} />
                                </div>
                                <div className="cimage">
                                    <img src={pro3} />
                                </div>
                                <div className="cimage">
                                    <img src={pro4} />
                                </div>
                                <div className="cimage">
                                    <img src={pro5} />
                                </div>
                            </Carousel>
                        </Col>
                        <Col>
                            <div className="detailBody">
                                <h1>Ayush Kavach | Boosts Natural Immunity | Fortifies Immune System | Relieves Stress, Improves Sleep | Enhances Vigor and Vitality | Detoxifies Blood & Respiratory System</h1>
                                <div>
                                    <div className="d-flex align-items-baseline">
                                        <small className="text-warning">
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                        </small>
                                        <p className="m-0 p-0">
                                            <span className="fs-5 ms-2">998 reviews </span>
                                        </p>
                                    </div>
                                    <p>
                                        <span className="benefits_span text-dark">Trusted by 1 Million+ Happy Customers </span>
                                    </p>
                                    <p className="product__text_description col-lg-10 col-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    <hr className="my-6"></hr>
                                    <div className="benefits__text mt-4">
                                        <h4>Benefits of PCOS Care Capsules</h4>
                                        <div className="benefitDescicons wn-flex col-lg-12 col-12">
                                            <div className="descIcons">
                                                <Image src={benef1}></Image>
                                                <span>Sugar Control</span>
                                            </div>
                                            <div className="descIcons">
                                                <Image src={benef2}></Image>
                                                <span>Remove Insulin Resistance</span>
                                            </div>
                                            <div className="descIcons">
                                                <Image src={benef3}></Image>
                                                <span>Improve Sugar Metabolism</span>
                                            </div>
                                            <div className="descIcons">
                                                <Image src={benef4}></Image>
                                                <span>Revitalize Energy & Mood</span>
                                            </div>
                                            <div className="descIcons">
                                                <Image src={benef5}></Image>
                                                <span>Increase insulin production</span>
                                            </div>
                                            <div className="descIcons">
                                                <Image src={benef6}></Image>
                                                <span>Antioxidant Activity</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="productPrice">
                                        <div className="pro-price d-flex">
                                            <div className="price">
                                                <span className="new-price">₹2,900.00</span>
                                                <span className="cutprice">
                                                    <del>₹3,100.00</del>
                                                </span>
                                                <span className="p-discount">Save 6.5%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Form className=" mt-4">
                                        <div className="varientSesction d-flex">
                                            <div className="varient">
                                                <input type="radio" name="varient" id="varient3" value="3 MONTHS" checked />
                                                <label className="varientBody" htmlFor="varient3">
                                                    <div className="varientDetail">
                                                        <p className="packType">3 MONTHS</p>
                                                        <p className="packSave">Save Rs. 3,895</p>
                                                        <h4 className="packPrice">Rs. 2,099</h4>
                                                        <del className="cutprice">Rs. 5,994</del>
                                                        <h6 className="totaltablet">540 TABLETS(PACK OF 6)RS.4/TABLET</h6>
                                                        <h6 className="pertablet">RS.4/TABLET</h6>
                                                    </div>
                                                    <p className="packname">Expert Recommend</p>
                                                </label>
                                            </div>
                                            <div className="varient">
                                                <input type="radio" name="varient" id="varient2" value="3 MONTHS" />
                                                <label className="varientBody" htmlFor="varient2">
                                                    <div className="varientDetail">
                                                        <p className="packType">2 MONTHS</p>
                                                        <p className="packSave">Save Rs. 3,895</p>
                                                        <h4 className="packPrice">Rs. 2,099</h4>
                                                        <del className="cutprice">Rs. 5,994</del>
                                                        <h6 className="totaltablet">540 TABLETS(PACK OF 6)RS.4/TABLET</h6>
                                                        <h6 className="pertablet">RS.4/TABLET</h6>
                                                    </div>
                                                    <p className="packname">Bestseller</p>
                                                </label>
                                            </div>
                                            <div className="varient">
                                                <input type="radio" name="varient" id="varient1" value="1 MONTHS" />
                                                <label className="varientBody" htmlFor="varient1">
                                                    <div className="varientDetail">
                                                        <p className="packType">1 MONTHS</p>
                                                        <p className="packSave">Save Rs. 3,895</p>
                                                        <h4 className="packPrice">Rs. 2,099</h4>
                                                        <del className="cutprice">Rs. 5,994</del>
                                                        <h6 className="totaltablet">540 TABLETS(PACK OF 6)RS.4/TABLET</h6>
                                                        <h6 className="pertablet">RS.4/TABLET</h6>
                                                    </div>
                                                    <p className="packname">Start Seeing Results</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="buttonSection">
                                            <Button className="addtocart">Add To Cart</Button>
                                            <Button className="buyitnow">Buy It Now</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="marquees mt-4">
                    <TopMarquee />
                </div>
                <section className="section-tab product_top bg-tab" id="navbar">
                    <div className="container">
                        <div className="all-tab">
                            <ul id="ulTabs">
                                <li className=""> <a className="" href="#Benefits">Key Benefits</a> </li>
                                <li className=""> <a className="" href="#Ingredients">Key Ingredients</a> </li>
                                <li className=""> <a className="" href="#How-to-Use">How to Use</a> </li>
                                <li className=""> <a className="" href="#Product-Details">Product Details</a> </li>
                                <li className=""> <a className="" href="#Testimonials">Testimonials</a> </li>
                                <li className=""> <a className="" href="#Faqs">FAQs</a> </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <div id="Benefits">
                    <section className="section-all">
                        <div className="container">
                            <div className="t-tittle text-center mt-4 mb-4">
                                <h2 className="">Key <span>Benefits</span></h2>
                            </div>
                            <div className="key-benefit">
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-6">
                                        <div className="use-card text-center">
                                            <img className="" data-sizes="auto" data-src="https://cdn.shopify.com/s/files/1/0602/2440/5694/files/pcos_benefit_01.png?v=1647949727" alt="Ayurvedic medicine for PCOS benefit 1" src="https://cdn.shopify.com/s/files/1/0602/2440/5694/files/pcos_benefit_01.png?v=1647949727" />
                                            <p>Helps restore hormonal balance </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-6">
                                        <div className="use-card text-center">
                                            <img className="" data-sizes="auto" data-src="https://cdn.shopify.com/s/files/1/0602/2440/5694/files/pcos_benefit_02.png?v=1647949727" alt="Ayurvedic medicin for PCOS benefit 2" src="https://cdn.shopify.com/s/files/1/0602/2440/5694/files/pcos_benefit_02.png?v=1647949727" />
                                            <p>Supports regular ovulation &amp; uterine health </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-6">
                                        <div className="use-card text-center">
                                            <img className="" data-sizes="auto" data-src="https://cdn.shopify.com/s/files/1/0602/2440/5694/files/pcos_benefit_03.png?v=1647949727" alt="PCOS Care Relieves Stress and Anxiety" src="https://cdn.shopify.com/s/files/1/0602/2440/5694/files/pcos_benefit_03.png?v=1647949727" />
                                            <p>Helps relieve stress &amp; anxiety </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-6">
                                        <div className="use-card text-center">
                                            <img className="" data-sizes="auto" data-src="https://cdn.shopify.com/s/files/1/0602/2440/5694/files/pcos_benefit_04.png?v=1647949727" alt="Ayurvedic medicine for PCOS improves emotional health" src="https://cdn.shopify.com/s/files/1/0602/2440/5694/files/pcos_benefit_04.png?v=1647949727" />
                                            <p>Helps improve emotional health </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div id="Video">
                    <Container>
                        <div className="t-tittle text-center mt-4 mb-4">
                            <h2 className="">Key Ingredients</h2>
                        </div>
                        <Row>
                            <Col md={6}>
                                <div className="video">
                                    <iframe width="100%" height="350" src="https://www.youtube.com/embed/8xVldmCaM4Y" title="'World Happiness Day&quot; Celebrated by SK Vision Foundation on- 20.03.2024" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="descingredients">
                                    <div className="title-wrapper title-wrapper--no-top-margin">
                                        <h2 className="videoTitle ">Is Happy Tummies for me?</h2>
                                    </div>
                                    <div className="title-wrapper title-wrapper--no-top-margin">
                                        <p className="videoContnt">Is it for my kids?  This is the right synbiotic for your kids if they: Don’t eat enough healthy foods Frequently get stomach upsets or indigestion Have low levels of energy Need to improve their overall health and immunity</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
                <div id="Ingredients">
                    <section className="section-all">
                        <div className="container">
                            <div className="t-tittle text-center mt-4 mb-4">
                                <h2 className="">Key <span>Ingredients</span></h2>
                            </div>
                            <div className="key-ingredient">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-sm-6 col-6 mb-4 text-center">
                                        <div className="ing-box">
                                            <img data-sizes="auto" data-src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/Safed-Musli.webp" src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/Safed-Musli.webp" alt="Safed Musli" />
                                        </div>
                                        <div className="ing-box-text">
                                            <h4 className="my-1">Safed Musli</h4>
                                            <p className="mb-0 small">Considered a tonic (rasayana) in ayurveda, this powerful medicinal herb restores immunity by increasing antibodies.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-6 mb-4 text-center">
                                        <div className="ing-box">
                                            <img data-sizes="auto" data-src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/ing-1.jpg" src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/ing-1.jpg" alt="Ashwagandha" />
                                        </div>
                                        <div className="ing-box-text">
                                            <h4 className="my-1">Ashwagandha</h4>
                                            <p className="mb-0 small">Ashwagandha is a powerful adaptogen, relives stress and improves brain function, boosts vitality &amp; vigor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-6 mb-4 text-center">
                                        <div className="ing-box">
                                            <img data-sizes="auto" data-src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/Safed-Musli.webp" src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/Safed-Musli.webp" alt="Shatavari" />
                                        </div>
                                        <div className="ing-box-text">
                                            <h4 className="my-1">Shatavari</h4>
                                            <p className="mb-0 small">Is known to boost immune cell production and has antiviral properties to fight viruses and pathogens.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-6 mb-4 text-center">
                                        <div className="ing-box">
                                            <img data-sizes="auto" data-src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/ing-1.jpg" src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/ing-1.jpg" alt="Cinnamon" />
                                        </div>
                                        <div className="ing-box-text">
                                            <h4 className="my-1">Cinnamon</h4>
                                            <p className="mb-0 small">Known for its antimicrobial, antifungal and antiviral properties, protects against respiratory diseases.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div id="How-to-Use">
                    <section className="steps section-all">
                        <div className="container">
                            <div className="t-tittle text-center mb-5">
                                <h2 className="">How to <span>use </span></h2>
                            </div>
                            <div className="row align-items-center pb-md-3">
                                <div className="col-md-6 col-lg-6 mb-4 mb-md-0">
                                    <img className="rounded-1 w-100" src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/steps.webp" alt="Steps" />
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="">
                                        <div className="step">
                                            <h4 className="">Take 1 capsules daily after dinner with milk or water</h4>
                                        </div>
                                        <div className="step">
                                            <h4 className="">Maintain a healthy diet, engage in regular exercises</h4>
                                        </div>
                                        <div className="step">
                                            <h4 className="">Use for 2-3 months for best results</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div id="Product-Details">
                    <section className="section-product-details section-all">
                        <div className="container">
                            <div className="t-tittle mt-4 mb-4">
                                <h2 className="">Product <span> Details</span></h2>
                            </div>
                            <div className="row product-inner-part px-40">
                                <div className="col-lg-12 proven-part">
                                    <p className="mt-responsive">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>
                                    <p className="mt-2">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>

                                    <div className="moretext" >
                                        <h4 className="">Who Should Take It? </h4>
                                        <p className="mt-2">Herbobuild is a doctor-researched and approved formula to achieve lean muscle mass and peak fitness for these consumers:</p>
                                        <ul>
                                            <li><b>Irregular periods :</b> PCOS care greatly benefits those who suffer from irregular periods to regulate their cycle. </li>
                                            <li><b>Painful cramps :</b> To help alleviate the pain caused during periods, the PCOS care can come as a great aid. </li>
                                            <li><b>Hormonal imbalance :</b> Hormonal imbalance can cause acne problems and unwanted hair growth. People suffering from this can take the PCOS Care to regulate hormone secretion. </li>
                                            <li><b>PCOS:</b> Individuals diagnosed with PCOS can make use of this ayurvedic remedy to cope with the symptoms of PCOS. </li>
                                        </ul>
                                        <div className="tab-pane fade active show" id="px_information" role="tabpanel" aria-labelledby="pd_information_tab">
                                            <div className="row">
                                                <h4 className="">Product Details </h4>
                                                <div className="col-lg-6">
                                                    <table className="table text-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th className="fw-500 text-mode">Product #</th>
                                                                <td className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisic elit</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="fw-500 text-mode">Available packaging</th>
                                                                <td className="text-muted">consectetur adipisic elit eiusm tempor</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="fw-500 text-mode">Weight</th>
                                                                <td className="text-muted">Ut enim ad minim venialo quis nostrud</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="fw-500 text-mode">Sunt in culpa qui</th>
                                                                <td className="text-muted">labore et dolore magna aliqua.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-lg-6">
                                                    <table className="table text-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th className="fw-500 text-mode">Weight</th>
                                                                <td className="text-muted">dolor sit amet</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="fw-500 text-mode">Sunt in culpa qui</th>
                                                                <td className="text-muted">Lorem ipsum dolor sit amet</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="fw-500 text-mode">Product #</th>
                                                                <td className="text-muted">Lorem ipsum dolor sit amet</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="fw-500 text-mode">Available packaging</th>
                                                                <td className="text-muted">Lorem ipsum dolor sit amet</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div id="testimonial">
                    <Container>
                        <div className="t-tittle text-center mt-4 mb-4">
                            <h2 className="">WHAT PEOPLE SAY ABOUT US</h2>
                        </div>
                        <Row>
                            <Col md={4} className="mb-2 mt-2">
                                <Card className="homereating">
                                    <Card.Body>
                                        <div className="namerating">
                                            <span className="rating">
                                                4.5 <FaStar />
                                            </span>
                                            <div className="user-name">
                                                <h6 content="Kiara Keshvani">Sharath</h6>
                                            </div>
                                        </div>
                                        <Card.Text>
                                            "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                        </Card.Text>
                                        <div className="postdate">
                                            <span className="duration">
                                                a year ago
                                            </span>
                                            <div className="location">
                                                <h6 className="user-location"> Ambala, Punjab</h6>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4} className="mb-2 mt-2">
                                <Card className="homereating">
                                    <Card.Body>
                                        <div className="namerating">
                                            <span className="rating">
                                                4.5 <FaStar />
                                            </span>
                                            <div className="user-name">
                                                <h6 content="Kiara Keshvani">Sharath</h6>
                                            </div>
                                        </div>
                                        <Card.Text>
                                            "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                        </Card.Text>
                                        <div className="postdate">
                                            <span className="duration">
                                                a year ago
                                            </span>
                                            <div className="location">
                                                <h6 className="user-location"> Ambala, Punjab</h6>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4} className="mb-2 mt-2">
                                <Card className="homereating">
                                    <Card.Body>
                                        <div className="namerating">
                                            <span className="rating">
                                                4.5 <FaStar />
                                            </span>
                                            <div className="user-name">
                                                <h6 content="Kiara Keshvani">Sharath</h6>
                                            </div>
                                        </div>
                                        <Card.Text>
                                            "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                        </Card.Text>
                                        <div className="postdate">
                                            <span className="duration">
                                                a year ago
                                            </span>
                                            <div className="location">
                                                <h6 className="user-location"> Ambala, Punjab</h6>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4} className="mb-2 mt-2">
                                <Card className="homereating">
                                    <Card.Body>
                                        <div className="namerating">
                                            <span className="rating">
                                                4.5 <FaStar />
                                            </span>
                                            <div className="user-name">
                                                <h6 content="Kiara Keshvani">Sharath</h6>
                                            </div>
                                        </div>
                                        <Card.Text>
                                            "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                        </Card.Text>
                                        <div className="postdate">
                                            <span className="duration">
                                                a year ago
                                            </span>
                                            <div className="location">
                                                <h6 className="user-location"> Ambala, Punjab</h6>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4} className="mb-2 mt-2">
                                <Card className="homereating">
                                    <Card.Body>
                                        <div className="namerating">
                                            <span className="rating">
                                                4.5 <FaStar />
                                            </span>
                                            <div className="user-name">
                                                <h6 content="Kiara Keshvani">Sharath</h6>
                                            </div>
                                        </div>
                                        <Card.Text>
                                            "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                        </Card.Text>
                                        <div className="postdate">
                                            <span className="duration">
                                                a year ago
                                            </span>
                                            <div className="location">
                                                <h6 className="user-location"> Ambala, Punjab</h6>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4} className="mb-2 mt-2">
                                <Card className="homereating">
                                    <Card.Body>
                                        <div className="namerating">
                                            <span className="rating">
                                                4.5 <FaStar />
                                            </span>
                                            <div className="user-name">
                                                <h6 content="Kiara Keshvani">Sharath</h6>
                                            </div>
                                        </div>
                                        <Card.Text>
                                            "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                        </Card.Text>
                                        <div className="postdate">
                                            <span className="duration">
                                                a year ago
                                            </span>
                                            <div className="location">
                                                <h6 className="user-location"> Ambala, Punjab</h6>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="Certification">
                    <section className="section-all">
                        <div className="container">
                            <div className="t-tittle text-center mt-4 mb-4">
                                <h2 className="">Our  <span>Certification</span></h2>
                            </div>
                            <div className="our-certification">
                                <div className="row justify-content-center">
                                    <div className="col-lg-2 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img data-sizes="auto" data-src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/ayush.webp" src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/ayush.webp" alt="Ayush" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img data-sizes="auto" data-src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/gmp.webp" src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/gmp.webp" alt="GMP" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img data-sizes="auto" data-src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/iso.webp" src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/iso.webp" alt="ISO" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img data-sizes="auto" data-src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/madeinindia.webp" src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/madeinindia.webp" alt="Made in India" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="faq">
                    <Container>
                        <div className="t-tittle text-center mt-4 mb-4">
                            <h2 className="">FREQUENTLY ASKED QUESTIONS</h2>
                        </div>
                        <div className="faqbody">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What is Ayush Kavach?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What is Ayush Kavach?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Container>
                </div>
                <MarketPlaceMarquee />
            </div>
            <Footer />
        </div>
    )
}

export default ProductDetail