import React from 'react'
import { Container } from 'react-bootstrap'
import BlogCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from 'react-bootstrap/Card';

import blog1 from "../../assets/blog/blog1.webp"
import blog2 from "../../assets/blog/blog2.webp"
import blog3 from "../../assets/blog/blog3.webp"
import blog4 from "../../assets/blog/blog4.webp"
import blog5 from "../../assets/blog/blog5.webp"
import { Link } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3020, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const HomeBlog = () => {
    return (
        <div className="secondbackgroundcolor">
            <div className="desktop">
                <Container>
                    <div className="descHeader text-center">
                        <h2>FROM OUR AYURVEDA & WELLNESS BLOG</h2>
                        <div className="desc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, reiciendis. Magni corrupti consequatur ratione? Quibusdam voluptas nesciunt maiores, minima asperiores reprehenderit expedita consequatur perspiciatis sapiente sequi illum neque provident velit. Explore our collection of top selling Ayurvedic products and alternative medicines in India.</p>
                        </div>
                    </div>
                    <BlogCarousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog1} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog2} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog3} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog4} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog5} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog1} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog2} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                    </BlogCarousel>
                </Container>
            </div>
            <div className="mobile mobileconcern">
                <Container>
                    <div className="descHeader text-center">
                        <h2>FROM OUR AYURVEDA & WELLNESS BLOG</h2>
                        <div className="desc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, reiciendis. Magni corrupti consequatur ratione? Quibusdam voluptas nesciunt maiores, minima asperiores reprehenderit expedita consequatur perspiciatis sapiente sequi illum neque provident velit. Explore our collection of top selling Ayurvedic products and alternative medicines in India.</p>
                        </div>
                    </div>
                    <div className="mobilehomeblog">
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog1} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog2} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog3} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog4} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog5} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog1} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                        <Card className="homeblog">
                            <Card.Img variant="top" src={blog2} />
                            <Card.Body>
                                <Card.Title><FaUserAlt />Author:Nikhil Chandra</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Link to="/">Read More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default HomeBlog