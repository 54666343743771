import React, { useState } from 'react'
import { Container } from 'react-bootstrap'

const FooterText = () => {
    const [showFullDescription, setFullDescription] = useState(false);
    const showFullDescriptionHandler = () => {
        setFullDescription(!showFullDescription);
    };
    const footerText = `<h2>Authentic Ayurveda: Sat Kartar Shopping Limited</h2>

    <p>Ayurveda, literally meaning “science of life”, is an ancient system of medicine that originated over 3,000 years ago in India.&nbsp;</p>

    <p>Ayurveda is based on the principle that health is a state of balance between the mind, body, and spirit.&nbsp;</p>

    <p>The Ayurvedic philosophy emphasizes the importance of maintaining this balance to achieve optimal health and well-being. Ayurveda is a holistic system of healing that focuses on treating the whole person, rather than just the symptoms of a disease or illness.</p>

    <p>Ayurveda is based on the concept of three doshas, which are the fundamental energies that govern all aspects of our health and well-being.&nbsp;</p>

    <p>The three doshas are Vata, Pitta, and Kapha. Each dosha is responsible for different functions in the body, and when they are in balance, we experience good health. However, when there is an imbalance in the doshas, it can lead to various health problems.</p>

    <p>Ayurveda values a holistic approach to health and well-being. It emphasizes the importance of a healthy lifestyle, including diet, exercise, and stress management. Ayurveda also values the use of natural remedies and herbal medicines to treat various health conditions.</p>

    <p>Ayurveda also places great importance on the mind-body connection. According to Ayurveda, our thoughts and emotions can have a significant impact on our physical health. Therefore, Ayurveda encourages practices such as meditation, yoga, and pranayama to help balance the mind and body.</p>

    <p>The fundamental principle of Ayurvedic holistic healing is that each person is unique and has their own individual needs. Ayurvedic treatments are tailored to the individual, taking into account their dosha type, lifestyle, and health condition. This personalized approach ensures that each person receives the most effective treatment for their specific needs.</p>

    <p>Ayurveda also emphasizes the importance of prevention. Ayurvedic practitioners believe that the best way to maintain good health is to prevent disease before it occurs. This can be achieved through a healthy lifestyle, including a balanced diet, regular exercise, and stress management techniques.</p>

    <p>Ayurveda also places great importance on the concept of digestion. According to Ayurveda, good digestion is the key to good health. This is because the food we eat is the source of our energy and vitality. Therefore, Ayurveda emphasizes the importance of eating foods that are easy to digest and that are appropriate for our dosha type.</p>

    <p>In Ayurveda, there are six tastes: sweet, sour, salty, bitter, pungent, and astringent. Each taste has a different effect on the body, and Ayurvedic practitioners use this knowledge to recommend foods that are appropriate for a person's dosha type and health condition.</p>

    <p>Ayurveda also values the use of herbs and spices to promote health and prevent disease. Many Ayurvedic herbs and spices have powerful medicinal properties and are used to treat a wide range of health conditions.</p>

    <p>In a nutshell, Ayurveda is a holistic system of healing that values the mind-body connection, the importance of a healthy lifestyle, and the use of natural remedies and herbal medicines to treat various health conditions. Ayurveda is based on the principle that each person is unique and has their own individual needs.</p>

    <h2>Buy Best Ayurvedic Medicine at Sat Kartar Shopping</h2>

    <p>Ayurvedic formulations by Sat Kartar Shopping Ayurvedic treatments that are tailored to the individual, taking into account their dosha type, lifestyle, and health condition.</p>

    <p>By promoting good health and preventing disease, our authentic herbal medicines offers a holistic approach to health and well-being that can help people achieve optimal health and vitality.</p>

    <p>We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>`;

    const description = showFullDescription
        ? footerText
        : `${footerText ? footerText.substring(0, 380) : ""}...`;
    return (
        <div className="firstbackgroundcolor">
            <Container>
                <div className="footer-text">
                    <div onClick={showFullDescriptionHandler}>
                        <div dangerouslySetInnerHTML={{ __html: description }}>
                        </div>
                        <button className="readMoreText">Read {showFullDescription ? "Less" : "More"}</button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default FooterText