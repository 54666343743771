import React from 'react'
import Header from '../components/Header.'
import Slider from '../components/homepage/Slider'
import VideoSection from '../components/homepage/VideoSection'
import BestSeller from '../components/homepage/BestSeller'
import NewArrival from '../components/homepage/NewArrival'
import AuthenticAyurveda from '../components/homepage/AuthenticAyurveda'
import ShopByConcern from '../components/homepage/ShopByConcern'
import OurCirtification from '../components/homepage/OurCirtification'
import ConsultDoctor from '../components/homepage/ConsultDoctor'
import Review from '../components/homepage/Review'
import HomeBlog from '../components/homepage/HomeBlog'
import Footer from '../components/Footer'
import TopMarquee from '../components/homepage/TopMarquee'
import MarketPlaceMarquee from '../components/homepage/MarketPlaceMarquee'
import OfferBanner from '../components/homepage/OfferBanner'
import ProductCounter from '../components/homepage/ProductCounter'
import FooterText from '../components/homepage/FooterText'
import { Helmet } from "react-helmet";

const Home = () => {
    return (
        <div className="homepage">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <Slider />
            <TopMarquee />
            <VideoSection />
            <BestSeller />
            <ShopByConcern />
            <NewArrival />
            <OurCirtification />
            <ProductCounter />
            <ConsultDoctor />
            <AuthenticAyurveda />
            <OfferBanner />
            <Review />
            <MarketPlaceMarquee />
            <HomeBlog />
            <FooterText />
            <Footer />
        </div>
    )
}

export default Home