import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { BiPhoneCall } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import { IoMdHelpCircle } from 'react-icons/io';
import { FaUserDoctor } from "react-icons/fa6";
import LoginPopup from './LoginPopup';

const TopBar = () => {
    const [loginPopupShow, setLoginPopupShow] = useState(false);
    return (
        <div className="topbar">
            <LoginPopup
                show={loginPopupShow}
                onHide={() => setLoginPopupShow(false)}
            />
            <div className="container">
                <div className="wrapper-top-bar">
                    <div className="col-md-3">
                        <div className="leftTopBar">
                            <span className="callNow">
                                Call Now :
                            </span>
                            <Link to="tel:+91-9319728256" className="displayNumber">
                                <BiPhoneCall className="userIcons" />+91-9319728256
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="marqueeBar">
                            <marquee className="callNow" behavior="scroll" direction="left">
                                FREE SHIPPING
                            </marquee>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="rightTopBar">
                            <span onClick={() => setLoginPopupShow(true)} className="userLogin">
                                <FaUserAlt className="userIcons" />Login
                            </span>
                            <span className="seperator">|</span>
                            <Link to="/contact-us" className="help">
                                <FaUserDoctor className="helpIcons" />
                                Talk to expert
                            </Link>
                            <span className="seperator">|</span>
                            <Link to="/contact-us" className="help">
                                <IoMdHelpCircle className="helpIcons" />
                                Need Help?
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar