import React from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import { FaStar } from 'react-icons/fa'

const Testimonial = () => {
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Testimonials</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumbs">
                            <li class="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Testimonials</li>
                        </ol>
                    </nav>
                    <Row className="testimonialbody mt-2">
                        <div className="titledesc">
                            <h1 class="text-center">WHAT OUR CUSTOMER SAY ABOUT US</h1>
                            <p>We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>
                        </div>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="homereating">
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                            4.5 <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">Sharath</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "Love my Hubby Now More Than Ever! Finally found a pill that actually works for my husband! I was fed up of his performance but just couldn't share it with him for his self-respect. Really happy I found Liv Muztang"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                            a year ago
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> Ambala, Punjab</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Testimonial