import React from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'

const PrivacyPolicy = () => {
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Privacy Policy</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumbs">
                            <li class="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                        </ol>
                    </nav>
                    <Row className="aboutbody">
                        <h1 class="text-center">Privacy Policy</h1>
                        <div class="col-md-12 col-sm-12">
                            <div><p>Sat Kartar Shopping Limited ("we," "us," or "our") operates satkartar.in (the "Site"). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site.</p>

                                <h3>INFORMATION WE COLLECT</h3>

                                <p>Generally, you control the amount and type of information that you provide to us when using our website. Our legal basis for collecting and processing your personal data (PD) on Signup Forms is based on consent.</p>

                                <p>What Happens if You Don’t Give Us Your Personal Data (PD)? If you do not provide us with enough PD, we may not be able to provide you with all our products and services. However, you can access and use most parts of our website without giving us your PD.</p>

                                <h3>WE COLLECT YOUR PD IN THE FOLLOWING WAYS</h3>

                                <p><strong>Automatic Information.</strong> We automatically receive information from your web browser or mobile device. This information includes the name of the website from which you entered our website, if any, as well as the name of the website you’ll visit when you leave our website. This information also includes the IP address of your computer/the proxy server you use to access the Internet, your Internet service provider’s name, your web browser type, the type of mobile device, your computer operating system, and data about your browsing activity when using our website. We use all this information to analyze trends among our users to help improve our website.</p>

                                <p><strong>When Entering and Using Our Website</strong>. When you enter and use our website and agree to accept cookies, some of these cookies may contain your PD.</p>

                                <p><strong>When Buying Products or Services.</strong> If you buy products or services from us, we collect your first and last name, email address, physical address, credit card or other payment information and other information listed.</p>

                                <h3>OUR USE OF COOKIES</h3>

                                <p>Our website uses cookies. A cookie is a small piece of data or a text file that is downloaded to your computer or mobile device when you access certain websites. Cookies may contain text that can be read by the web server that delivered the cookie to you. The text contained in the cookie generally consists of a sequence of letters and numbers that uniquely identifies your computer or mobile device; it may contain other information as well.</p>

                                <p>By agreeing to accept our use of cookies, you are giving us, and third parties we partner with, permission to place, store, and access some or all the cookies described below on your computer.</p>

                                <p><strong>Strictly Necessary Cookies</strong>. These cookies are necessary for proper functioning of the website, such as displaying content, logging in, validating your session, responding to your request for services, and other functions. Most web browsers can be set to disable the use of cookies. However, if you disable these cookies, you may not be able to access features on our website correctly or at all. This also includes session cookies. These cookies allow websites to link the actions of a user during a browser session. They may be used for a variety of purposes, such as remembering what a user has put in their shopping cart as they browse a website. Session cookies also permit users to be recognized as they navigate a website so that any item or page changes they make are remembered from page to page. Session cookies expire after a browser session; thus, they are not stored long term.</p>

                                <p><strong>Performance Cookies</strong>. These cookies collect information about the use of the website, such as pages visited, traffic sources, users’ interests, content management, and other website measurements.</p>

                                <p><strong>Functional Cookies.</strong> These cookies enable the website to remember user’s choices – such as their language, username, and other personal choices – while using the website. They can also be used to deliver services, such as letting a user make a blog post, listen to the audio, or watch videos on the website. Functional cookies also include media cookies that can be used to improve a website’s performance and provide special features and content. They can be placed by third parties who provide services to us or by our company.</p>

                                <p><strong>Advertising or Targeting Cookies</strong>. These cookies are usually placed and used by advertising companies to develop a profile of your browsing interests and serve advertisements on other websites that are related to your interests. You will see less targeted and more irrelevant advertising if you disable these cookies.</p>

                                <p>We may also use cookies for:</p>

                                <ul>
                                    <li>Identifying the areas of our website that you have visited</li>
                                    <li>Personalizing content that you see on our website</li>
                                    <li>Our website analytics</li>
                                    <li>Remarketing our products or services to you</li>
                                    <li>Remembering your preferences, settings, and login details</li>
                                    <li>Targeted advertising and serving ads relevant to your interests</li>
                                    <li>Affiliate marketing</li>
                                    <li>Allowing you to post comments</li>
                                    <li>Allowing you to share content with social networks</li>
                                </ul>

                                <p><br />
                                    Most web browsers can be set to disable the use of cookies. However, if you disable cookies, you may not be able to access some features on our website correctly or at all.</p>

                                <h3>Doubleclick DART cookie</h3>

                                <p>Google, as a third party vendor, may use cookies to serve ads on www.satkartar.co.in.<br />
                                    Google’s use of the DART cookie enables it to serve ads to users based on their visit to www.satkartar.co.in and other sites on the Internet.</p>

                                <p>Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at the following URL – http://www.google.com/privacy_ads.html</p>

                                <p><br />
                                    Some of our advertising partners may use cookies and web beacons on our site. Our advertising partners include Google Adsense. These third-party ad servers or ad networks use technology to send advertisements and links that appear on www.satkartar.co.in directly to your browsers. They automatically receive your IP address when this occurs. Other technologies (such as cookies, JavaScript, or Web Beacons) may also be used by the third-party ad networks to measure the effectiveness of their advertisements and/or to personalize the advertising content that you see.</p>

                                <p>Sat Kartar Shopping Limited has no access to or control over these cookies that are used by third-party advertisers. You should consult the respective privacy policies of these third-party ad servers for more detailed information on their practices as well as for instructions about how to opt-out of certain practices. Sat Kartar Shopping Limited’s privacy policy does not apply to, and we cannot control the activities of, such other advertisers or websites.</p>

                                <p>By declining marketing/targeting cookies you will also decline all embedded ads to be displayed by the AdSense platform on satkartar.co.in.</p>

                                <p><strong>Web Beacons.</strong> We may also use a technology called web beacons to collect general information about your use of our website and your use of special promotions or newsletters. The information we collect by web beacons allows us to statistically monitor the number of people who open our emails. Web beacons also help us to understand the behavior of our customers, members, and visitors.</p>

                                <p><strong>Google Ad and Content Network Privacy notice.</strong> Third-party vendors, including Google, use cookies to serve ads based on a user’s past visits to our website. Google’s use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visits to our site and/or other sites on the Internet. Users may opt out of the use of the DoubleClick cookie for interest-based advertising by visiting http://www.aboutads.info/choices/ For European users visit http://www.youronlinechoices.eu</p>

                                <p><strong>Google Analytics Privacy notice</strong>. Our website uses Google Analytics to collect information about the use of our website. Google Analytics collects information from users such as age, gender, interests, demographics, how often they visit our website, what pages they visit, and what other websites they have used before coming to our website. We use the information we get from Google Analytics to analyze traffic, remarket our products and services to users, improve our marketing, advertising, and to improve our website. We have enabled Google Analytics advertising features such as remarketing with Google Analytics, Google Display Network Impression Reporting, and Google Analytics Demographics and Interest Reporting. Google Analytics collects only the IP address assigned to you on the date you visit our website, not your name or other identifying information. We do not combine the information collected using Google Analytics with PD. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit our website, the cookie cannot be used by anyone but Google. Google also uses specific identifiers to help collect information about the use of our website. For more information on how Google collects and processes your data, visit https://www.google.com/policies/privacy/partners/</p>

                                <p>You can prevent Google Analytics from using your information by opting out at this link: https://tools.google.com/dlpage/gaoptout</p>

                                <p><strong>Google Remarketing</strong>. Why am I seeing ads by Google for products I’ve viewed? Our website uses a remarketing advertising service. Our remarketing service is provided by Google and other companies that show our ads on websites across the Internet. With remarketing you may see ads for our products you have previously looked at. As an example, suppose you visit a website that sells computers, but you do not buy a computer on your first visit to that website. The website’s owner might like to encourage you to revisit his/her site and buy a computer by showing you his/her ads again on other websites that you visit. We use remarketing for similar purposes. For this to happen, Google will read a cookie that is already in your browser, or they place a cookie in your browser when you visit our site or other sites using remarketing.<br />
                                    You can opt out of Google’s use of cookies and remarketing at this link: https://support.google.com/ads/answer/2662922?hl=en or you can opt out using the Network Advertising Initiative opt-out page at: http://optout.networkadvertising.org/#!/</p>

                                <p><strong>Facebook Remarketing.</strong> Third parties, including Facebook, may use cookies, web beacons, and other storage technologies to collect or receive information from our website and elsewhere on the internet, and use that information to provide measurement services and target ads. With Facebook remarketing, you may see our ads on Facebook after you have visited our site. For this to happen, Facebook uses a Custom Audience Pixel that is activated when a visitor lands on a webpage and a unique “cookie” is placed in their browser. Facebook lookalike audience targeting allows us to show ads on Facebook to people who are similar to those who have already visited or made a purchase from our website. To opt out of Facebook’s collection and use of information for ad targeting visit: https://www.facebook.com/help/568137493302217</p>

                                <h3>HOW YOUR INFORMATION IS USED</h3>

                                <p>We use the information we receive from you to:</p>

                                <ul>
                                    <li>Provide our products and services you have requested or purchased from us</li>
                                    <li>Personalize and customize our content</li>
                                    <li>Make improvements to our website</li>
                                    <li>Contact you with updates to our website</li>
                                    <li>Resolve problems and disputes</li>
                                    <li>Contact you with marketing and advertising that we believe may be of interest to you</li>
                                </ul>

                                <p><strong>Communications and Emails.</strong> When we communicate with you about our website, we will use the email address you provided when you registered as a member or user. We may also send you emails with promotional information about our website or offers from us or our affiliates unless you have opted out of receiving such information. You can change your contact preferences at any time through your account or by sending us an email with your request to: info [ @ ] satkartar.co.in</p>

                                <p><strong>Sharing Information with Affiliates and Other Third Parties.</strong> We do not sell or rent your PD to third parties for marketing purposes.</p>

                                <p><strong>Sharing Information with Facebook and Google for Marketing Purposes.</strong> We may share your PD with third parties for similar audience marketing purposes. Similar audience marketing is also called lookalike audience marketing. The third parties we share your PD with for this type of marketing include Facebook and/or Google. Using your PD, for similar audience marketing or lookalike audience marketing helps us find new audiences (users and customers) based on similar interests to yours, this helps us improve our marketing services. Your PD is only shared with Facebook and Google for this type of marketing. By using our website and agreeing to our privacy notice, you are giving your consent for your PD to be used for the marketing purposes described in this section.</p>

                                <p><strong>Legally Required Releases of Information.</strong> We may be legally required to disclose your PD if such disclosure is (a) required by subpoena, law, or other legal processes; (b) necessary to assist law enforcement officials or government enforcement agencies; (c) necessary to investigate violations of or otherwise enforce our Legal Terms; (d) necessary to protect us from legal action or claims from third parties, including you and/or other users or members; or (e) necessary to protect the legal rights, personal/real property, or personal safety of our company, users, employees, and affiliates.</p>

                                <p><strong>Disclosures to Successors.</strong> If our business is sold or merges in whole or in part with another business that would become responsible for providing the website to you, we retain the right to transfer your PD to the new business. The new business would retain the right to use your PD according to the terms of this privacy notice as well as to any changes to this privacy notice as instituted by the new business.</p>

                                <p>We also retain the right to transfer your PD if our company files for bankruptcy and some or all of our assets are sold to another individual or business.</p>

                                <p><strong>Community Discussion Boards.</strong> Our website may offer the ability for users to communicate with each other through online community discussion boards or other mechanisms. We do not filter or monitor what is posted on such discussion boards. If you choose to post on these discussion boards, you should use care when exposing any PD, as such information is not protected by our privacy notice nor are we liable if you choose to disclose your PD through such postings. Also, the PD you post on our website for publication may be available worldwide by means of the Internet. We cannot prevent the use or misuse of such information by others.</p>

                                <h3>RETAINING AND DESTROYING YOUR PD</h3>

                                <p>We retain information that we collect from you (including your PD) only for as long as we need it for legal, business, or tax purposes. Your information may be retained in electronic form, paper form, or a combination of both. When your information is no longer needed, we will destroy, delete, or erase it.</p>

                                <h3>UPDATING YOUR PD</h3>

                                <p>You can update your PD using services found on our website. If no such services exist, you can contact us using the contact information found at the top of this notice and we will help you. However, we may keep your PD as needed to enforce our agreements and to comply with any legal obligations.</p>

                                <h3>REVOKING YOUR CONSENT FOR USING YOUR PD</h3>

                                <p>You have the right to revoke your consent for us to use your PD at any time. Such an opt out will not affect disclosures otherwise permitted by law including but not limited to: (i) disclosures to affiliates and business partners, (ii) disclosures to third-party service providers that provide certain services for our business, such as credit card processing, computer system services, shipping, data management services, (iii) disclosures to third parties as necessary to fulfill your requests, (iv) disclosures to governmental agencies or law enforcement departments, or as otherwise required to be made under applicable law, (v) previously completed disclosures to third parties, or (vi) disclosures to third parties in connection with subsequent contests or promotions you may choose to enter, or third-party offers you may choose to accept. If you want to revoke your consent for us to use your PD, send us an email with your request to: info [ at ] satkartar.co.in</p>

                                <h3>PROTECTING THE PRIVACY RIGHTS OF THIRD PARTIES</h3>

                                <p>If any postings you make on our website contain information about third parties, you must make sure you have permission to include that information in your posting. While we are not legally liable for the actions of our users, we will remove any postings about which we are notified, if such postings violate the privacy rights of others.</p>

                                <h3>Contact Us</h3>

                                <p>If you have any questions about this Privacy Policy, please contact us.</p>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy