import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Product from "./pages/Product";
import Ingredients from "./pages/Ingredients";
import ShopByConcern from "./pages/ShopByConcern";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundCancellationPolicy from "./pages/RefundCancellationPolicy";
import ShippingPolicy from "./pages/ShippingPolicy";
import Testimonial from "./pages/Testimonial";
import Faqs from "./pages/Faqs";
import ProductDetail from "./pages/ProductDetail";
import CheckoutLogin from "./pages/CheckoutLogin";
import DeliveryAddress from "./pages/DeliveryAddress";
import OrderSummary from "./pages/OrderSummary";
import PaymentOption from "./pages/PaymentOption";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Product />} />
          <Route path="/products/:slug" element={<ProductDetail />} />
          <Route path="/ingredients" element={<Ingredients />} />
          <Route path="/shop-by-concern" element={<ShopByConcern />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/term-and-condition" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundCancellationPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/checkout-login" element={<CheckoutLogin />} />
          <Route path="/delivery-address" element={<DeliveryAddress />} />
          <Route path="/order-summary" element={<OrderSummary />} />
          <Route path="/payment-options" element={<PaymentOption />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
