import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header.'
import Footer from '../components/Footer'
import { Step, Stepper } from 'react-form-stepper'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaRegTrashCan } from 'react-icons/fa6'

const DeliveryAddress = () => {
    const [values, setUserValue] = useState({
        user_mobile: '',
        delivery_name: '',
        delivery_mobile: '',
        delivery_addresse: '',
        delivery_landmark: '',
        delivery_city: '',
        delivery_pincode: '',
        delivery_state: '',
        delivery_country: '',
        cart_data: '',
        totalamt: '',
        userid: '',
    });
    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Checkout Login</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="contentBody mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Login</li>
                        </ol>
                    </nav>
                    <div className="steper">
                        <Row>
                            <div className="spetbar-card">
                                <Stepper activeStep={1}>
                                    <Step label="LOGIN" />
                                    <Step label="DELIVERY ADDRESS" />
                                    <Step label="ORDER SUMMARY" />
                                    <Step label="PAYMENT OPTIONS" />
                                </Stepper>
                            </div>
                        </Row>
                    </div>
                    <div className="contentBody">
                        <div className="account-card">
                            <div className="account-title">
                                <h4>Delivery Address</h4>
                            </div>
                            <div className="account-contenrt ddeliveryBottom">
                                <div className="deliverynames">
                                    <div className="deliveryname">
                                        <b>Sushil Kumar Raj</b>
                                        <b className="deliverynumber">9876543210</b>
                                    </div>
                                    <div className="deliveryBtn">
                                        <button className="btnRemove"><FaRegTrashCan /></button>
                                    </div>
                                </div>
                                <div className="adeliveryaddress">
                                    <span>6th Floor, Unit Number 603, Mercantile House, K.G. Marg, New Delhi, Delhi, 110001</span>
                                </div>
                                <div className="adeliveryaddressbtn mb-4">
                                    <button>DELIVER HERE</button>
                                </div>
                            </div>
                        </div>
                        <div className="account-card">
                            <div className="account-title">
                                <h4>Add New Delivery Address</h4>
                            </div>
                            <div className="account-content">
                                <form method="post">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="delivery_name">Name</label>
                                            <input type="text" className="form-control" id="delivery_name" name="delivery_name" placeholder="Name" required={true} onChange={handleInputs} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="delivery_mobile">Mobile</label>
                                            <input type="number" className="form-control" id="delivery_mobile" placeholder="Mobile" name="delivery_mobile" required={true} onChange={handleInputs} minLength={10} maxLength={10} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="delivery_addresse">Full Address</label>
                                            <textarea className="form-control" id="delivery_addresse" name="delivery_addresse" placeholder="Flat, House No, Building, Apartment, Area, Street, Sector, Village" required={true} rows={1} onChange={handleInputs}></textarea>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="delivery_landmark">Landmark</label>
                                            <input type="text" className="form-control" id="delivery_landmark" name="delivery_landmark" placeholder="Landmark" onChange={handleInputs} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="delivery_city">City</label>
                                            <input type="text" className="form-control" id="delivery_city" name="delivery_city" placeholder="City" onChange={handleInputs} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="delivery_pincode">Pincode</label>
                                            <input type="number" className="form-control" id="delivery_pincode" name="delivery_pincode" placeholder="Pincode" required={true} onChange={handleInputs} minLength={6} maxLength={6} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="delivery_state">State</label>
                                            <input type="text" className="form-control" id="delivery_state" name="delivery_state" placeholder="State" required={true} onChange={handleInputs} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="delivery_country">Country</label>
                                            <input type="text" className="form-control" id="delivery_country" name="delivery_country" placeholder="Country" required={true} onChange={handleInputs} />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <div className="form-group">
                                                <button className="btncheckcont" name="login">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default DeliveryAddress