import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header.'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Step, Stepper } from 'react-form-stepper'
import Footer from '../components/Footer'

const PaymentOption = () => {
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Payment Option</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="contentBody mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">payment-option</li>
                        </ol>
                    </nav>
                    <div className="steper">
                        <Row>
                            <div className="spetbar-card">
                                <Stepper activeStep={3}>
                                    <Step label="LOGIN" />
                                    <Step label="DELIVERY ADDRESS" />
                                    <Step label="ORDER SUMMARY" />
                                    <Step label="PAYMENT OPTIONS" />
                                </Stepper>
                            </div>
                        </Row>
                    </div>
                    <Row className="mt-4">
                        <Col md={8}>
                            <div class="account-card">
                                <div class="account-title">
                                    <h4>Payment Options</h4>
                                </div>
                                <div class="account-content">
                                    <form method="post">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 paymentType">
                                                <div class="paymentmethod">
                                                    <input type="radio" id="paymentmethod" name="paymentmethod" required="" value="PAY" />
                                                    <label for="paymentmethod">PAY NOW</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3 paymentType">
                                                <div class="paymentmethod">
                                                    <input type="radio" id="paymentmethod" name="paymentmethod" required="" value="COD" />
                                                    <label for="paymentmethod">COD(Cash On Delivery)</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <div class="form-group">
                                                    <button type="submit" class="btncheckcont">PLACE ORDER</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div class="account-card">
                                <div class="account-title">
                                    <h4>Price Details</h4>
                                </div>
                                <div class="price-summary">
                                    <div class="price-summary-details">
                                        <span>Sub Total</span>
                                        <span>₹2,999.00</span>
                                    </div>
                                    <div class="price-summary-details">
                                        <span>Discount</span>
                                        <span class="priceCharge">
                                            <b>0.00</b>
                                        </span>
                                    </div>
                                    <div class="price-summary-details">
                                        <span>Delivery Charges</span>
                                        <span class="priceCharge">
                                            <b>Free</b>
                                        </span>
                                    </div>
                                    <div class="price-summary-details total-amount-details">
                                        <span>
                                            <b>Amount Payable</b>
                                        </span>
                                        <span>
                                            <b>₹2,999.00</b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default PaymentOption