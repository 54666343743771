import React from 'react'
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import SCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import shop1 from "../../assets/shop/shop1.png"
import shop2 from "../../assets/shop/shop2.png"
import shop3 from "../../assets/shop/shop3.png"
import shop4 from "../../assets/shop/shop4.png"
import shop5 from "../../assets/shop/shop5.png"
import shop6 from "../../assets/shop/shop6.png"
import shop7 from "../../assets/shop/shop7.png"
import shop8 from "../../assets/shop/shop8.png"


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 8
    },
    desktop: {
        breakpoint: { max: 3020, min: 1024 },
        items: 6
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

const ShopByConcern = () => {
    return (
        <>
            <div className="desktop">
                <Container className="bodySection shopByConcernsection">
                    <div className="descHeader text-center">
                        <h2>SHop By Concern: Sat Kartar Group</h2>
                        <div className="desc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, reiciendis. Magni corrupti consequatur ratione? Quibusdam voluptas nesciunt maiores, minima asperiores reprehenderit expedita consequatur perspiciatis sapiente sequi illum neque provident velit. Explore our collection of top selling Ayurvedic products and alternative medicines in India.</p>
                        </div>
                    </div>
                    <SCarousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                        <div className="shopByConcern">
                            <Image src={shop1} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop2} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop3} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop4} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop5} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop6} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop7} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop8} rounded />
                        </div>
                    </SCarousel>
                </Container>
            </div>
            <div className="mobile mobileconcern">
                <Container>
                <div className="descHeader text-center">
                        <h2>SHop By Concern: Sat Kartar Group</h2>
                        <div className="desc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, reiciendis. Magni corrupti consequatur ratione? Quibusdam voluptas nesciunt maiores, minima asperiores reprehenderit expedita consequatur perspiciatis sapiente sequi illum neque provident velit. Explore our collection of top selling Ayurvedic products and alternative medicines in India.</p>
                        </div>
                    </div>
                    <div className="mobileshopby">
                        <div className="shopByConcern">
                            <Image src={shop1} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop2} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop3} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop4} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop5} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop6} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop7} rounded />
                        </div>
                        <div className="shopByConcern">
                            <Image src={shop8} rounded />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default ShopByConcern