import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Header from '../components/Header.'
import ing1 from "../assets/ing/ing1.webp"
import ing2 from "../assets/ing/ing2.webp"

const Ingredients = () => {
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Ingredients</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumbs">
                            <li class="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">ingredients</li>
                        </ol>
                    </nav>
                    <Row>
                        <Col md={3} sm={12}>
                            <Card className="ingredient-body">
                                <Card.Img variant="top" src={ing1} />
                                <Card.Body>
                                    <Card.Title>Tulsi (Holy Basil)</Card.Title>
                                    <Card.Text className="ingdesc">
                                        <p>Also known as tulsi, holy basil has powerful antioxidant and anti-inflammatory properties, potentially protecting organs like the liver from drug-related damage.It is regarded as "queen of herbs" in Ayurveda.&nbsp;<a href="#">Read More</a></p>
                                        <p><strong>Used in</strong>: <a href="#">Addiction Killer</a></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="ingredient-body">
                                <Card.Img variant="top" src={ing2} />
                                <Card.Body>
                                    <Card.Title>Tulsi (Holy Basil)</Card.Title>
                                    <Card.Text className="ingdesc">
                                        <p>Also known as tulsi, holy basil has powerful antioxidant and anti-inflammatory properties, potentially protecting organs like the liver from drug-related damage.It is regarded as "queen of herbs" in Ayurveda.&nbsp;<a href="#">Read More</a></p>
                                        <p><strong>Used in</strong>: <a href="#">Addiction Killer</a></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="ingredient-body">
                                <Card.Img variant="top" src={ing1} />
                                <Card.Body>
                                    <Card.Title>Tulsi (Holy Basil)</Card.Title>
                                    <Card.Text className="ingdesc">
                                        <p>Also known as tulsi, holy basil has powerful antioxidant and anti-inflammatory properties, potentially protecting organs like the liver from drug-related damage.It is regarded as "queen of herbs" in Ayurveda.&nbsp;<a href="#">Read More</a></p>
                                        <p><strong>Used in</strong>: <a href="#">Addiction Killer</a></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="ingredient-body">
                                <Card.Img variant="top" src={ing2} />
                                <Card.Body>
                                    <Card.Title>Tulsi (Holy Basil)</Card.Title>
                                    <Card.Text className="ingdesc">
                                        <p>Also known as tulsi, holy basil has powerful antioxidant and anti-inflammatory properties, potentially protecting organs like the liver from drug-related damage.It is regarded as "queen of herbs" in Ayurveda.&nbsp;<a href="#">Read More</a></p>
                                        <p><strong>Used in</strong>: <a href="#">Addiction Killer</a></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="ingredient-body">
                                <Card.Img variant="top" src={ing1} />
                                <Card.Body>
                                    <Card.Title>Tulsi (Holy Basil)</Card.Title>
                                    <Card.Text className="ingdesc">
                                        <p>Also known as tulsi, holy basil has powerful antioxidant and anti-inflammatory properties, potentially protecting organs like the liver from drug-related damage.It is regarded as "queen of herbs" in Ayurveda.&nbsp;<a href="#">Read More</a></p>
                                        <p><strong>Used in</strong>: <a href="#">Addiction Killer</a></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="ingredient-body">
                                <Card.Img variant="top" src={ing2} />
                                <Card.Body>
                                    <Card.Title>Tulsi (Holy Basil)</Card.Title>
                                    <Card.Text className="ingdesc">
                                        <p>Also known as tulsi, holy basil has powerful antioxidant and anti-inflammatory properties, potentially protecting organs like the liver from drug-related damage.It is regarded as "queen of herbs" in Ayurveda.&nbsp;<a href="#">Read More</a></p>
                                        <p><strong>Used in</strong>: <a href="#">Addiction Killer</a></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="ingredient-body">
                                <Card.Img variant="top" src={ing1} />
                                <Card.Body>
                                    <Card.Title>Tulsi (Holy Basil)</Card.Title>
                                    <Card.Text className="ingdesc">
                                        <p>Also known as tulsi, holy basil has powerful antioxidant and anti-inflammatory properties, potentially protecting organs like the liver from drug-related damage.It is regarded as "queen of herbs" in Ayurveda.&nbsp;<a href="#">Read More</a></p>
                                        <p><strong>Used in</strong>: <a href="#">Addiction Killer</a></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12}>
                            <Card className="ingredient-body">
                                <Card.Img variant="top" src={ing2} />
                                <Card.Body>
                                    <Card.Title>Tulsi (Holy Basil)</Card.Title>
                                    <Card.Text className="ingdesc">
                                        <p>Also known as tulsi, holy basil has powerful antioxidant and anti-inflammatory properties, potentially protecting organs like the liver from drug-related damage.It is regarded as "queen of herbs" in Ayurveda.&nbsp;<a href="#">Read More</a></p>
                                        <p><strong>Used in</strong>: <a href="#">Addiction Killer</a></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Ingredients