import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';
import img1 from "../../assets/cirt/cirt1.webp"
import img2 from "../../assets/cirt/cirt2.webp"
import img3 from "../../assets/cirt/cirt3.webp"
import img4 from "../../assets/cirt/cirt4.webp"

const OurCirtification = () => {
    return (
        <div className="firstbackgroundcolor">
            <div className="desktop">
                <Container fluid="md">
                    <div className="descHeader text-center">
                        <h2>Our Cirtification</h2>
                        <div className="desc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, reiciendis. Magni corrupti consequatur ratione? Quibusdam voluptas nesciunt maiores, minima asperiores reprehenderit expedita consequatur perspiciatis sapiente sequi illum neque provident velit. Explore our collection of top selling Ayurvedic products and alternative medicines in India.</p>
                        </div>
                    </div>
                    <Row className="cirtification">
                        <Col>
                            <div className="cirtificationbody">
                                <Image src={img1}></Image>
                                <div className="heading">
                                    <h3>GMP Certified</h3>
                                </div>
                                <p className="heading">Delivering high standard & authentic ayurvedic products</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="cirtificationbody">
                                <Image src={img2}></Image>
                                <div className="heading">
                                    <h3>100% Organic</h3>
                                </div>
                                <p className="heading">Ingredients sourced indigenously from mountains & forests</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="cirtificationbody">
                                <Image src={img3}></Image>
                                <div className="heading">
                                    <h3>Ayush Approved</h3>
                                </div>
                                <p className="heading">Approved by Ministry of Ayush, Government of India</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="cirtificationbody">
                                <Image src={img4}></Image>
                                <div className="heading">
                                    <h3>Full Convenience</h3>
                                </div>
                                <p className="heading">All India free shipping & cash on delivery option available</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="mobile mobileconcern">
                <Container fluid="md">
                    <div className="descHeader text-center">
                        <h2>Our Cirtification</h2>
                        <div className="desc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, reiciendis. Magni corrupti consequatur ratione? Quibusdam voluptas nesciunt maiores, minima asperiores reprehenderit expedita consequatur perspiciatis sapiente sequi illum neque provident velit. Explore our collection of top selling Ayurvedic products and alternative medicines in India.</p>
                        </div>
                    </div>
                    <Row className="cirtification">
                        <Col>
                            <div className="cirtificationbody">
                                <Image src={img1}></Image>
                                <div className="heading">
                                    <h3>GMP Certified</h3>
                                </div>
                                <p className="heading">Delivering high standard & authentic ayurvedic products</p>
                            </div>
                            <div className="cirtificationbody mt-2">
                                <Image src={img2}></Image>
                                <div className="heading">
                                    <h3>100% Organic</h3>
                                </div>
                                <p className="heading">Ingredients sourced indigenously from mountains & forests</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="cirtificationbody">
                                <Image src={img3}></Image>
                                <div className="heading">
                                    <h3>Ayush Approved</h3>
                                </div>
                                <p className="heading">Approved by Ministry of Ayush, Government of India</p>
                            </div>
                            <div className="cirtificationbody mt-2">
                                <Image src={img4}></Image>
                                <div className="heading">
                                    <h3>Full Convenience</h3>
                                </div>
                                <p className="heading">All India free shipping & cash on delivery option available</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default OurCirtification