import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import doctor from "../../assets/doctor/doctor.webp"
import { Image } from 'react-bootstrap';

const ConsultDoctor = () => {
    return (
        <div className="consultant">
            <div className="desktop">
                <Container>
                    <div className="descHeader text-center">
                        <h2 className="heading">GET A FREE CONSULTATION FROM OUR AYURVEDIC EXPERT</h2>
                    </div>
                    <Row>
                        <Col xs={8}>
                            <div className="drdesc">
                                <h3>Book a 30 Minutes 1-1 Session with Dr. Mansi</h3>
                                <p><strong>Specialization: </strong>BAMS (Bachelor of Ayurveda, Medicine, and Surgery)</p>
                                <p><strong>Total Experience: </strong>6+ Years</p>
                                <p><strong>Consultation Language Known: </strong>Hindi, English, Panjabi&nbsp;</p>
                                <p className="talkdrdesc">Dr. Mansi is a prominent Ayurvedic doctor with over 6 years plus of experience. She has enriched lives as an Ayurveda Physician at Sat Kartar Group, a leading Ayurvedic medicine provider in India. She is an expert in treating issues like male and female hormonal disorders, piles/ fissure, vitality, weight management, liver disorder, Arthritis, diabetes management, addiction etc. Dr. Mansi offers a unique holistic approach to medicinal treatment as well as spiritual empowerment.</p>
                                <div className="btns">
                                    <button className="btntalk"> Book Your Free Consultation Now</button>
                                </div>
                            </div>
                        </Col>
                        <Col className="image">
                            <Image src={doctor}></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="mobile mobileconcern">
                <Container>
                    <div className="descHeader text-center">
                        <h2 className="heading">GET A FREE CONSULTATION FROM OUR AYURVEDIC EXPERT</h2>
                    </div>
                    <Row className="mobiledoctor">
                        <Col className="image">
                            <Image src={doctor}></Image>
                            <div className="drdesc">
                                <h3>Book a 30 Minutes 1-1 Session with Dr. Mansi</h3>
                                <p><strong>Specialization: </strong>BAMS (Bachelor of Ayurveda, Medicine, and Surgery)</p>
                                <p><strong>Total Experience: </strong>6+ Years</p>
                                <p><strong>Consultation Language Known: </strong>Hindi, English, Panjabi&nbsp;</p>
                                <p className="talkdrdesc">Dr. Mansi is a prominent Ayurvedic doctor with over 6 years plus of experience. She has enriched lives as an Ayurveda Physician at Sat Kartar Group, a leading Ayurvedic medicine provider in India. She is an expert in treating issues like male and female hormonal disorders, piles/ fissure, vitality, weight management, liver disorder, Arthritis, diabetes management, addiction etc. Dr. Mansi offers a unique holistic approach to medicinal treatment as well as spiritual empowerment.</p>
                                <div className="btns">
                                    <button className="btntalk"> Book Your Free Consultation Now</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    );
}

export default ConsultDoctor