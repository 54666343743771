import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import video1 from "../../assets/video/video1.mp4"
import video2 from "../../assets/video/video2.mp4"
import video3 from "../../assets/video/video3.mp4"
import video4 from "../../assets/video/video4.mp4"
import video5 from "../../assets/video/video5.mp4"
import video6 from "../../assets/video/video6.mp4"
import VideoPopup from './VideoPopup';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const VideoSection = () => {
    const [videopopupShow, setVideoPopupShow] = useState(false);
    const [videoid, setVideoId] = useState("");
    const handleVideoPopup = (videoid) => {
        setVideoId(videoid)
        setVideoPopupShow(true)
    }
    return (
        <div className="div" bg="light">
            <VideoPopup
                show={videopopupShow}
                onHide={() => setVideoPopupShow(false)}
                videoid={videoid}
            />
            <Container className="videosection desktop">
                <Carousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={256} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video1} type="video/mp4" />
                            <source src={video1} type="video/ogg" />
                        </video>
                    </div>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={256} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video2} type="video/mp4" />
                            <source src={video2} type="video/ogg" />
                        </video>
                    </div>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={256} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video3} type="video/mp4" />
                            <source src={video3} type="video/ogg" />
                        </video>
                    </div>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={256} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video4} type="video/mp4" />
                            <source src={video4} type="video/ogg" />
                        </video>
                    </div>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={256} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video5} type="video/mp4" />
                            <source src={video5} type="video/ogg" />
                        </video>
                    </div>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={256} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video6} type="video/mp4" />
                            <source src={video6} type="video/ogg" />
                        </video>
                    </div>
                </Carousel>
            </Container>
            <Container className="mobile">
                <div className="mvideosection">
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={200} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video1} type="video/mp4" />
                            <source src={video1} type="video/ogg" />
                        </video>
                    </div>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={200} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video2} type="video/mp4" />
                            <source src={video2} type="video/ogg" />
                        </video>
                    </div>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={200} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video3} type="video/mp4" />
                            <source src={video3} type="video/ogg" />
                        </video>
                    </div>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={200} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video4} type="video/mp4" />
                            <source src={video4} type="video/ogg" />
                        </video>
                    </div>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={200} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video5} type="video/mp4" />
                            <source src={video5} type="video/ogg" />
                        </video>
                    </div>
                    <div className="videobody" onClick={() => handleVideoPopup(1)}>
                        <video width={200} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                            <source src={video6} type="video/mp4" />
                            <source src={video6} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default VideoSection