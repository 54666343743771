import React from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'

const ShippingPolicy = () => {
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Shipping Policy</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumbs">
                            <li class="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Shipping Policy</li>
                        </ol>
                    </nav>
                    <Row className="aboutbody">
                        <h1 class="text-center">Shipping Policy</h1>
                        <div class="col-md-12 col-sm-12">
                            <div>
                                <p>At Sat Kartar Shopping Limited, we understand the importance of timely delivery of your orders. That's why we work with reliable shipping carriers to get your products to you as quickly and safely as possible. Below you'll find everything you need to know about our shipping policies.</p>

                                <h3>Order Processing Time</h3>

                                <p>We strive to process all orders within 2 business days from the time they are placed. During high volume periods, such as holidays, processing time may be slightly longer. If there is any delay in processing your order, we will notify you via email.</p>

                                <h3>Delivery Time</h3>

                                <p>Usually, we ship your order from 48 hours to 5 working days. The delivery time for your order will depend on the shipping method you selected and the destination of your order. We partner with reputable shipping carriers to ensure that your order arrives in a timely manner. Please note that delivery times may be longer during peak shipping seasons or due to weather conditions.</p>

                                <h3>Order Changes and Cancellations</h3>

                                <p>If you need to make changes or cancel your order, please contact us as soon as possible. We will do our best to accommodate your request, but please note that we may not be able to make changes or cancel your order once it has been processed.</p>

                                <h3>Shipping Address</h3>

                                <p>Please ensure that your shipping address is correct and complete when placing your order. We are not responsible for orders that are shipped to an incorrect or incomplete address.</p>

                                <h3>Lost or Damaged Packages</h3>

                                <p>In the event that your package is lost or damaged during shipping, please contact us as soon as possible. We will work with the shipping carrier to resolve the issue and get your order to you as quickly as possible.</p>

                                <p>If you have any questions about our shipping policies, please don't hesitate to contact us. We're here to help!</p>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default ShippingPolicy